import React from "react";

const MGtsu = () => {
  return (
    <svg
      id="_\uB808\uC774\uC5B4_1"
      xmlns="http://www.w3.org/2000/svg"
      width="150px"
      height="115px"
      viewBox="0 0 150 115"
      // {...props}
    >
      <defs>
        <style>
          {
            ".cls-1{fill:none;stroke:#f0f0f0;stroke-miterlimit:10;stroke-width:1.5px;}"
          }
        </style>
      </defs>
      <g id="_\uADF8\uB8F9_206">
        <g id="_\uADF8\uB8F9_205">
          <g id="_\uADF8\uB8F9_203">
            <path
              id="_\uD328\uC2A4_547"
              className="cls-1"
              d="M139.69,100.36H62.54"
            />
          </g>
          <g id="_\uADF8\uB8F9_204">
            <rect
              id="_\uC0AC\uAC01\uD615_116"
              className="cls-1"
              x={10.31}
              y={51.68}
              width={129.37}
              height={5.37}
            />
            <rect
              id="_\uC0AC\uAC01\uD615_117"
              className="cls-1"
              x={69.42}
              y={43.91}
              width={3.68}
              height={56.45}
            />
            <rect
              id="_\uC0AC\uAC01\uD615_118"
              className="cls-1"
              x={115.37}
              y={43.91}
              width={3.68}
              height={56.45}
            />
            <rect
              id="_\uC0AC\uAC01\uD615_119"
              className="cls-1"
              x={100.1}
              y={29.77}
              width={28.14}
              height={21.92}
            />
            <rect
              id="_\uC0AC\uAC01\uD615_120"
              className="cls-1"
              x={100.1}
              y={43.91}
              width={28.14}
              height={7.78}
            />
            <rect
              id="_\uC0AC\uAC01\uD615_121"
              className="cls-1"
              x={69.42}
              y={14.64}
              width={3.68}
              height={25.03}
            />
            <path
              id="_\uD328\uC2A4_548"
              className="cls-1"
              d="M71.25,41.18l-1.65,2.85h3.29l-1.65-2.85-1.65,2.85h3.29l-1.65-2.85Z"
            />
            <path
              id="_\uD328\uC2A4_549"
              className="cls-1"
              d="M71.25,42.72l1.65-2.85h-3.29l1.65,2.85,1.65-2.85h-3.29l1.64,2.85Z"
            />
            <path
              id="_\uD328\uC2A4_550"
              className="cls-1"
              d="M15.83,77.28l6.99-2.83,6.72,2.83c-.88,3.01-3.73,5-6.86,4.81,0,0-4.03,.42-6.86-4.81Z"
            />
            <line
              id="_\uC120_126"
              className="cls-1"
              x1={22.82}
              y1={57.06}
              x2={22.82}
              y2={82.08}
            />
            <line
              id="_\uC120_127"
              className="cls-1"
              x1={24.45}
              y1={51.68}
              x2={69.42}
              y2={14.64}
            />
            <path
              id="_\uD328\uC2A4_551"
              className="cls-1"
              d="M73.09,14.64l42.28,39.73-42.28,45.99"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MGtsu;
