import React from "react";
import styles from "@styles/dataShare/dataDashboard.module.css";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

const TextCard = ({ title, interval, amount, unit, desc }: any) => {
  const persent =
    amount[1] === 0
      ? "-"
      : (((amount[0] - amount[1]) / amount[1]) * 100).toFixed(2) + "%";
  return (
    <div className={styles.textCard}>
      <div className={styles.title}>
        {title}
        <span style={{ fontSize: "14px" }}>/{interval}</span>
      </div>
      <div className={styles.innerCard}>
        <div>
          <div className={styles.amount}>{amount[0].toLocaleString()}</div>
          <div className={styles.unit}>{unit}</div>
        </div>

        <div
          style={{
            fontSize: "14px",
          }}
        >
          {desc}({persent})
          {amount[0] > amount[1] ? (
            <CaretUpOutlined style={{ color: "green", fontSize: "20px" }} />
          ) : (
            <CaretDownOutlined style={{ color: "red", fontSize: "20px" }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TextCard;
