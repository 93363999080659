import React from 'react';
import {Navigate} from 'react-router-dom';

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const isLogin = !!localStorage.getItem('refreshToken');

  // 이미 로그인한 사용자가 로그인 페이지에 접근하려고 하면 홈으로 이동
  if (isLogin) {
    return <Navigate to={'/'} replace />;
  }

  return children;
};

export default PublicRoute;