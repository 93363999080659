import React, { useState } from "react";
import { Button, Form, Select, DatePicker, TimePicker } from "antd";
import {
  EmissionType,
  EmissionClassification,
  EmissionActivity,
  ActivityData,
} from "../../types/enum/enum";
import styles from "../../styles/carbon/carbonSearchComponent.module.css";
import dayjs from "dayjs";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:00";

/**************************************************/
const today = dayjs().format(dateFormat);
/**************************************************/
const stdStartTime = "00:00";
const stdEndTime = dayjs().format(timeFormat);
export interface SearchValues {
  emission: string;
  emissionClassification: string;
  emissionActivity: string;
  activityData: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

export const initSearchValues = {
  emission: EmissionType.INDIRECT,
  emissionClassification: EmissionClassification.ELECTRICITY,
  emissionActivity: EmissionActivity.INDIRECT_ELECTRICITY,
  activityData: ActivityData.ELECTRICITY,
  // emission: "직접 배출원(Scope 1)",
  // emissionClassification: "고정 연소",
  // emissionActivity: "이동연소(항공)",
  // activityData: "천연가스 액(kl)",
  startDate: today,
  startTime: stdStartTime,
  endDate: today,
  endTime: stdEndTime,
};

const SearchComponent = ({ onSearch }: any) => {
  const [searchValues, setSearchValues] =
    useState<SearchValues>(initSearchValues);

  const onClickInit = () => {
    setSearchValues({ ...initSearchValues });
    //console.log("onClickInit > searchValues", searchValues);
  };

  const handleSearch = () => {
    onSearch(searchValues);
    console.log("handleSearch > searchValues", searchValues);
  };

  // const renderOptions = (list: string[]) =>
  //   list.map((x: string) => {
  //     return { value: x, label: x };
  //   });
  const renderOptions = (enumObj: Record<string, string>) =>
    Object.values(enumObj).map((value: string) => ({
      value,
      //label: i18n.t(value),
      label: value,
    }));

  return (
    <Form>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          border: "1px solid #f0f0f0",
          padding: "10px",
          // backgroundColor: "#00021d",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <div style={{ display: "flex" }}>
          <Select
            style={{ width: "150px" }}
            value={searchValues["emission"]}
            className={styles.cSelect}
            onChange={(value) => {
              setSearchValues({ ...searchValues, emission: value });
            }}
            // options={renderOptions([
            //   "직접 배출원(Scope 1)",
            //   "간접 배출원(Scope 2)",
            // ])}
            options={renderOptions(EmissionType)}
          />
          <Select
            style={{ width: "150px" }}
            value={searchValues.emissionClassification}
            className={styles.cSelect}
            onChange={(value) => {
              setSearchValues({
                ...searchValues,
                emissionClassification: value,
              });
            }}
            //options={renderOptions(["고정 연소", "이동 연소", "전기", "열/스팀"])}
            options={renderOptions(EmissionClassification)}
          />
          <Select
            style={{ width: "150px" }}
            value={searchValues.emissionActivity}
            className={styles.cSelect}
            onChange={(value) => {
              setSearchValues({ ...searchValues, emissionActivity: value });
            }}
            // options={renderOptions([
            //   "이동연소(항공)",
            //   "간접 배출(외부전기 사용)",
            //   "외부열 사용(A타입)",
            // ])}
            options={renderOptions(EmissionActivity)}
          />
          <Select
            defaultValue=""
            style={{ width: "150px" }}
            value={searchValues.activityData}
            className={styles.cSelect}
            onChange={(value) => {
              setSearchValues({ ...searchValues, activityData: value });
            }}
            // options={renderOptions([
            //   "천연가스 액(kl)",
            //   "휘발유(kl)",
            //   "전기(kwh)",
            //   "스팀(GT)",
            // ])}
            options={renderOptions(ActivityData)}
          />
          <DatePicker
            className={styles.cDatePicker}
            defaultValue={dayjs(today, dateFormat)}
            value={dayjs(searchValues.startDate, dateFormat)}
            format={dateFormat}
            allowClear={false}
            onChange={(date, dateString) => {
              setSearchValues({ ...searchValues, startDate: dateString as string });
            }}
          />
          <TimePicker
            className={styles.cTimePicker}
            defaultValue={dayjs(stdStartTime, timeFormat)}
            value={dayjs(searchValues.startTime, timeFormat)}
            format={timeFormat}
            allowClear={false}
            onChange={(time: any, timeString) => {
              setSearchValues({ ...searchValues, startTime: timeString as string});
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#00a5e5",
              margin: "0px 5px",
            }}
          >
            ~
          </div>
          <DatePicker
            className={styles.cDatePicker}
            defaultValue={dayjs(today, dateFormat)}
            value={dayjs(searchValues.endDate, dateFormat)}
            format={dateFormat}
            allowClear={false}
            onChange={(date, dateString) => {
              setSearchValues({ ...searchValues, endDate: dateString as string});
            }}
          />
          <TimePicker
            className={styles.cTimePicker}
            defaultValue={dayjs(stdEndTime, timeFormat)}
            value={dayjs(searchValues.endTime, timeFormat)}
            // minuteStep={60}
            format={timeFormat}
            allowClear={false}
            onChange={(time: any, timeString) => {
              setSearchValues({ ...searchValues, endTime: timeString as string});
            }}
          />
        </div>
        <div>
          <div>
            <Button
              className="initbtn"
              style={{ marginRight: "10px" }}
              onClick={() => onClickInit()}
            >
              초기화
            </Button>
            <Button
              type="primary"
              className="searchbtn"
              onClick={() => {
                handleSearch();
              }}
            >
              검색
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default SearchComponent;
