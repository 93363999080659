import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const GradientDonutChart = ({ data, category, colorList }: any) => {
  const [series, setSeries] = React.useState<ApexAxisChartSeries>([]);
  const [uCategory, setUCategory] = useState<any>();

  const chartOptions: ApexOptions = {
    labels: uCategory,
    plotOptions: {
      pie: {
        donut: {
          background: colorList.background,
          labels: {
            show: true,
            name: {
              show: true,
              color: "#FFFFFF",
              offsetY: 25,
              formatter: function () {
                return `${colorList.donutTitle}`;
              },
            },
            value: {
              show: true,
              fontSize: "20px",
              fontFamily: "Noto Sans KR",
              fontWeight: 400,
              offsetY: -20,
              color: "#FFFFFF",
            },
            total: {
              show: true,
              fontSize: "14px",
              fontFamily: "Noto Sans KR",
              fontWeight: 400,
              color: "#FFFFFF",
              formatter: function (w) {
                var total = w.globals.seriesTotals.reduce((a: any, b: any) => {
                  return a + b;
                }, 0);
                return total.toFixed(2);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return (
          Math.round(Number(val)) +
          "% " +
          "(" +
          opts.w.globals.series[opts.seriesIndex] +
          ")"
        );
      },
    },

    fill: {
      type: "gradient",
      gradient: {
        gradientToColors: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
        stops: [20, 100],
      },
    },
    legend: {
      show: false,
      position: "bottom",
      labels: {
        colors: "#F0F0F0",
        useSeriesColors: false,
      },
    },
    xaxis: {
      categories: uCategory,
    },
    colors: colorList.colors,
    stroke: { show: false },
  };

  useEffect(() => {
    let _c = [];
    for (let c of category) {
      _c.push(c.toUpperCase());
    }
    setUCategory(_c);
    setSeries(data);
  }, [data, category]);

  return (
    <div>
      <Chart
        options={chartOptions}
        type="donut"
        series={series}
        height={"190"}
        width={"190"}
      />
    </div>
  );
};

export default GradientDonutChart;
