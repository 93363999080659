import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { headerState } from "@store/atom";
import {
  Tabs,
  Drawer,
  Button,
  Spin,
  Radio,
  Tag,
  Table,
  Checkbox,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import { CloseCircleOutlined } from "@ant-design/icons";
import type { RadioChangeEvent } from "antd";
import TotalModelingTree from "./drawer/TotalModelingTree";
// import SearchConditionModal from "./SearchConditionModal";
import styles from "@styles/optFac/trend.module.css";
import service from "@services/OptFacService";
import DatePickerAndPeriod from "./DatePickerAndPeriod";
import type { PaginationProps, TabsProps } from "antd";
import DivisionEChart from "./chart/DivisionEChart";
import IntegratedEChart from "./chart/IntegratedEChart";
import IntegratedNormalizeEChart from "./chart/IntegratedNormalizeEChart";

const Trend = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);

  useEffect(() => {
    setHeader({
      title: t("main:header:optimumFacility"),
      subTitle: t("main:header:trend"),
      path: "/oms/monitoring/tag-chart",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [summaryCode, setSummaryCode] = useState<any>("MIN-MAX");
  const [displayCount, setDisplayCount] = useState(1000);
  const [isDisplayCount, setIsDisplayCount] = useState<boolean>(true);

  const activeTab = useRef<any>("Chart");
  const [isVisible, setIsVisible] = useState<boolean>(false); // drawer visible
  // const [target, setTarget] = useState<any>();
  const [viewTags, setViewTags] = useState([
    // {
    //   id: "PW-S204",
    //   name: "PW-S204",
    // },
    { id: "／POSCO／광양／반입／ST204／PW-S204", name: "PW-S204" },
    // {
    //   id: "／POSCO／광양／소결／DC501／AHP_A-101",
    //   name: "AHP_A-101",
    // },
    // { id: "／POSCO／광양／소결／DC501／CDP_A-101", name: "CDP_A-101" },
    // { id: "／POSCO／광양／소결／DC501／CIP_A-101", name: "CIP_A-101" },
    // { id: "／POSCO／광양／소결／DC501／COP_A-101", name: "COP_A-101" },
    // { id: "／POSCO／광양／소결／DC501／FGP_A-101", name: "FGP_A-101" },
    // { id: "／POSCO／광양／소결／DC501／HUMID-101", name: "HUMID-101" },
    // { id: "／POSCO／광양／소결／DC501／INP_A-101", name: "INP_A-101" },
    // { id: "／POSCO／광양／소결／DC501／OUP_A-101", name: "OUP_A-101" },
    // { id: "／POSCO／광양／소결／DC501／SDR_A-101", name: "SDR_A-101" },
    // { id: "／POSCO／광양／소결／DC501／SMM_A-101", name: "SMM_A-101" },
    // { id: "／POSCO／광양／소결／DC501／TEMP-101", name: "TEMP-101" },
  ]);

  // const [drawViewㄴTags, setDrawViewTags] = useState<any>([]); // 드로우를 활성화 하고 태그를 선택한 경우에 사용
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const handleOk = () => {
    // setViewTags(_.cloneDeep(drawViewTags));
    setIsTargetTag(false);
    setIsVisible(false);
  };

  // 로딩 중 spin

  // const showModal = () => {
  //   setIsVisible(true);
  // };

  // const handleCancel = () => {
  //   console.log("Button cancel clicked!");
  //   setIsVisible(false);
  // };

  // const modalOpen = () => {
  //   if (viewTags.length === 0) {
  //     alert("선택된 태그가 없습니다");
  //     return false;
  //   }
  // };

  const [radioChart, setRadioChart] = useState<String>("division");
  // const [radioChart, setRadioChart] = useState<String>("integrated");

  const [datePeriodComponentData, setDatePeriodComponentData] = useState({
    from: null,
    to: null,
    // from: "2022-07-18 20:30:00",
    // to: "2023-07-25 22:30:00",
    // from: "2017-03-14 06:00:00",
    // to: "2017-03-15 06:00:00",
  });

  const onSearch = () => {
    // if (!datePeriodComponentData.to || !datePeriodComponentData.from) {
    //   return;
    // }

    if (activeTab.current === "Chart") {
      getTagTrendChart();
    } else {
      getTagTrendTable();
    }
  };

  const [tagChartList, setTagChartList] = useState<any>(null);

  const spinStatus = (value: boolean) => {
    // console.log("들어오긴 해 ? ", value);
    setIsSpinning(value);
  };

  const getTagTrendChart = async () => {
    setIsSpinning(true);

    let params: any = [];
    for (let tag of viewTags) {
      params.push({
        date: {
          startT: dayjs(datePeriodComponentData.from).valueOf(),
          endT: dayjs(datePeriodComponentData.to).valueOf(),
          start: dayjs(datePeriodComponentData.from).format(
            "YYYY-MM-DD HH:mm:ss.SSS"
          ),
          end: dayjs(datePeriodComponentData.to).format(
            "YYYY-MM-DD HH:mm:ss.SSS"
          ),

          // startT: 1658176200,
          // endT: 1658183400,
          // start: "2022-07-18 20:30:00.000",
          // end: "2022-07-18 22:30:00.000",
        },
        type: summaryCode,
        displayCount: isDisplayCount ? -1 : displayCount,
        tagList: [tag],
      });
    }

    const promises = params.map((param: any) => {
      return new Promise(function (resolve, reject) {
        const res = service.getTagTrend(param);
        resolve(res);
      });
    });

    const tagList = await Promise.all(promises)
      .then((results) => {
        let responses: any = {};
        for (const result of results) {
          const key = Object.keys(result.data)[0];
          responses[key] = result.data[key];
        }
        return responses;
      })
      .catch((e) => {
        setIsSpinning(false);
        console.error(e);
      });

    let chartData: any = [];

    Object.keys(tagList).map((name) => {
      return chartData.push({ title: name, data: tagList[name] });
    });

    setIsSpinning(false);
    setTagChartList(chartData);
  };

  const [isTargetTag, setIsTargetTag] = useState(false);

  // TAG
  const removeTag = (e: any, tag: any) => {
    const updatedViewTags = viewTags.filter((obj) => obj.id !== tag.id);
    setViewTags(updatedViewTags);
  };

  const removeAllTag = () => {
    setViewTags([]);
  };

  // DRAWER
  const drawerClose = () => {
    setIsVisible(false);
  };

  const drawerOpen = () => {
    setIsTargetTag(false);
    setIsVisible(true);
  };

  const changeData = (tag: any) => {
    // if (viewTags.length >= 5) {
    //   alert(`최대 5개 까지만 선택할 수 있습니다.`);
    //   return;
    // }
    if (
      viewTags.filter((selectedTag) => selectedTag.id === tag.path).length > 0
    ) {
      alert(`이미 선택된 태그입니다.`);
      return;
    }

    const tagObj = { id: tag.path, name: tag.name };
    setViewTags((prevTags) => [...prevTags, tagObj]);
  };

  //  table
  const defaultColumns: any = [
    { title: "Tag Name", dataIndex: "tagName", align: "center" },
    { title: "Value", dataIndex: "value", align: "center" },
    { title: "Description", dataIndex: "description", align: "center" },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      width: "200px",
      render: (e: number) => (
        <span>{dayjs(e).format("YYYY-MM-DD HH:mm:ss")}</span>
      ),
    },
  ];

  const [dataList, setDataList] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const page = useRef<number>(1);
  const size = useRef<number>(10);
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = async (
    current,
    pageSize
  ) => {
    size.current = pageSize;
    getTagTrendTable();
    // setSearchCondition({ ...searchCondition, page: current, size: pageSize });
  };

  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    page.current = pageNumber;
    getTagTrendTable();
    // setSearchCondition({ ...searchCondition, page: pageNumber, size });
  };

  const getTagTrendTable = () => {
    setIsSpinning(true);

    const param = {
      tagList: viewTags,
      date: {
        startT: dayjs(datePeriodComponentData.from).valueOf(),
        endT: dayjs(datePeriodComponentData.to).valueOf(),
        start: dayjs(datePeriodComponentData.from).format(
          "YYYY-MM-DD HH:mm:ss.SSS"
        ),
        end: dayjs(datePeriodComponentData.to).format(
          "YYYY-MM-DD HH:mm:ss.SSS"
        ),
      },
      page: page.current,
      size: size.current,
    };

    // const param = {
    //   date: {
    //     startT: 1589848200000,
    //     endT: 1589851800000,
    //     start: "2020-05-19 09:30:00.000",
    //     end: "2020-05-19 10:30:00.000",
    //   },
    //   tagList: [
    //     {
    //       id: 1,
    //       name: "II1801",
    //     },
    //     {
    //       id: 2,
    //       name: "PI1822",
    //     },
    //   ],
    //   page: page.current,
    //   size: size.current,
    // };

    service.getTagTrendTable(param).then((res) => {
      setIsSpinning(false);
      const data = res.data.list;
      setTotalCount(res.data.totalCount);

      size.current = res.data.pageSize;
      let _r = [];
      for (let i in data) {
        const _data = {
          ...data[i],
          key: Number(i) + 1 + (page.current - 1) * size.current,
        };
        _r.push(_data);
      }
      setDataList(_r);
    });
  };

  const items: TabsProps["items"] = [
    {
      key: "Chart",
      label: `Chart`,
      children: (
        <>
          <div>
            <Radio.Group
              value={radioChart}
              onChange={(e: RadioChangeEvent) => {
                setRadioChart(e.target.value);
              }}
            >
              <Radio value={"division"}>분할</Radio>
              <Radio value={"integrated"}>통합</Radio>
              <Radio value={"integrated_normalize"}>통합 Normalize</Radio>
            </Radio.Group>
          </div>
          <div className={styles.trend_chart}>
            {radioChart === "division" &&
              tagChartList &&
              tagChartList.map((d: any) => (
                <DivisionEChart
                  tag={viewTags.find((tag) => tag.id === d.title)}
                  key={d.title}
                  title={d.title}
                  data={d.data}
                  spinStatus={spinStatus}
                  type={summaryCode}
                  displayCount={isDisplayCount ? -1 : displayCount}
                />
              ))}
            {radioChart === "integrated" && tagChartList && (
              <IntegratedEChart
                data={tagChartList}
                tagList={viewTags}
                type={summaryCode}
                displayCount={isDisplayCount ? -1 : displayCount}
              />
            )}
            {radioChart === "integrated_normalize" && tagChartList && (
              <IntegratedNormalizeEChart
                data={tagChartList}
                tagList={viewTags}
                type={summaryCode}
                displayCount={isDisplayCount ? -1 : displayCount}
              />
            )}
          </div>
        </>
      ),
    },
    {
      key: "Data",
      label: `Data`,
      children: (
        <div className={styles.trend_table}>
          <Table
            dataSource={dataList}
            columns={defaultColumns}
            // size="small"
            // scroll={{ y: tableHeight }}
            pagination={{
              onShowSizeChange: onShowSizeChange,
              current: page.current,
              onChange: onChange,
              total: totalCount,
              defaultCurrent: 1,
              pageSize: size.current,
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={styles.tagChartContainer}>
      <Spin spinning={isSpinning} tip="Loading...">
        <div className={styles.innerContainer}>
          <div className={styles.head}>
            <div style={{ display: "flex" }}>
              <DatePickerAndPeriod setDate={setDatePeriodComponentData} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <Radio.Group
                  value={summaryCode}
                  onChange={(e: RadioChangeEvent) => {
                    setSummaryCode(e.target.value);
                  }}
                >
                  <Radio value={"MIN-MAX"}>MIN-MAX</Radio>
                  <Radio value={"MIN"}>MIN</Radio>
                  <Radio value={"MAX"}>MAX</Radio>
                  <Radio value={"MEAN"}>MEAN</Radio>
                </Radio.Group>
                <div style={{ marginLeft: "30px" }}>Display Count</div>
                <InputNumber
                  min={1}
                  // max={10}
                  style={{ marginLeft: "10px" }}
                  defaultValue={displayCount}
                  onChange={(value) => setDisplayCount(value)}
                  disabled={isDisplayCount}
                />
                <Checkbox
                  style={{ marginLeft: "10px" }}
                  onChange={() => {
                    setIsDisplayCount(!isDisplayCount);
                  }}
                  checked={isDisplayCount}
                >
                  Default
                </Checkbox>
              </div>
            </div>
          </div>
          <div className={styles.head}>
            <div>
              <div className={styles.tag_btn_area}>
                <Button type="primary" onClick={drawerOpen}>
                  태그 선택
                </Button>
              </div>
              <div className={styles.tag_name_area}>
                <span
                  style={{ margin: "0px 5px" }}
                  onClick={() => removeAllTag()}
                >
                  <CloseCircleOutlined />
                </span>
                {viewTags.length > 0 &&
                  viewTags.map((tag) => (
                    <Tag
                      key={tag.id}
                      closable
                      onClose={(e) => removeTag(e, tag)}
                    >
                      {tag.name}
                    </Tag>
                  ))}
              </div>
              <Button
                type="primary"
                onClick={() => onSearch()}
                disabled={
                  !datePeriodComponentData.to || !datePeriodComponentData.from
                }
              >
                검색
              </Button>
            </div>
          </div>
          <div className={styles.tag_trend_body}>
            <Tabs
              key="tabs"
              onChange={(activeKey) => {
                activeTab.current = activeKey;
                onSearch();
              }}
              animated={false}
              tabPosition="top"
              type="card"
              className={styles.trend_tabs}
              style={{ height: "100%" }}
              items={items}
            />
            <Drawer
              className={styles.tag_chart_drawer}
              closable={false}
              open={isVisible}
              placement="right"
              title="Tag List"
              onClose={drawerClose}
              width={500}
            >
              <div className={styles.total_modeling_tree_container}>
                <div className={styles.total_modeling_tree_container_top}>
                  <TotalModelingTree
                    onChangeData={changeData}
                    // targetNode={target}
                  />
                </div>
                <div className={styles.total_modeling_tree_container_footer}>
                  <Button onClick={drawerClose}>취소</Button>
                  <Button
                    onClick={handleOk}
                    disabled={!isTargetTag}
                    type="primary"
                  >
                    확인
                  </Button>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </Spin>
      {/* 
      {conditionKey && (
        <SearchConditionModal
          visible={conditionKey}
          datePeriodComponentData={datePeriodComponentData}
          handleOk={conditionModalSave}
          handleCancel={modalClose}
        />
      )} */}
    </div>
  );
};

export default Trend;
