import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { headerState } from "@store/atom";
import { useTranslation } from "react-i18next";
import styles from "@styles/optFac/optimumFacilityDash.module.css";
import AreaChart from "./charts/AreaChart";
import BubbleChart from "./charts/BubbleChart";
import NumberCard from "./NumberCard";
import EqpList from "./EqpList";
import data from "@data/index.json";
import CycleModal from "./modal/CycleModal";
import FlowModal from "./modal/FlowModal";

const Dashboard = () => {
  const [isCycleModal, setIsCycleModal] = useState(false);
  const [isFlowModal, setIsFlowModal] = useState(false);

  const handleCancel = () => {
    if (isCycleModal) {
      setIsCycleModal(false);
    }
    if (isFlowModal) {
      setIsFlowModal(false);
    }
  };

  const openCycleModal = () => {
    setIsCycleModal(true);
  };
  const openFlowModal = () => {
    setIsFlowModal(true);
  };

  const [cWidth, setCWidth] = useState<any>(0);
  const [tHeight, setTHeight] = useState<any>(0);
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:optimumFacility"),
      subTitle: t("main:header:dashboard"),
      path: "/optimumFacility/dashboard",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eqpList = data["optimumFacility"]["dashboard"]["eqpList"];
  const areaChartData = data["optimumFacility"]["dashboard"]["areaChartData"];
  const bubbleChartData1 = data["optimumFacility"]["dashboard"]["bubbleChart1"];
  const bubbleChartData2 = data["optimumFacility"]["dashboard"]["bubbleChart2"];

  useEffect(() => {
    const tHeight = window.innerHeight;
    const cWidth = window.innerWidth;

    setCWidth(cWidth);
    setTHeight(tHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.template}>
      <div className={styles.leftContainer}>
        <div className={styles.div1}>
          <div className={styles.title}>전력 사용 현황</div>
          <div className={styles.chart}>
            <AreaChart
              series={areaChartData}
              height={tHeight * 0.3 - 40}
              width={cWidth * 0.4}
            />
          </div>
        </div>
        <div className={styles.div5} />
        <div className={styles.div2}>
          <div className={styles.inner}>
            <div className={styles.title}>설비 효율(설비효율 공식)</div>
            <div className={styles.numberCardContainer}>
              <NumberCard title={"타이틀"} value={999} />
              <NumberCard title={"타이틀"} value={999} />
              <NumberCard title={"타이틀"} value={999} />
              <NumberCard title={"타이틀"} value={999} />
              <NumberCard title={"타이틀"} value={999} />
            </div>
          </div>
          <div className={styles.div5} />
          <div className={styles.inner}>
            <div className={styles.title}>설비 효율(예측)</div>
            <div className={styles.numberCardContainer}>
              <NumberCard title={"타이틀"} value={999} subValue={0.1} />
              <NumberCard title={"타이틀"} value={999} subValue={0.1} />
              <NumberCard title={"타이틀"} value={999} subValue={0.1} />
              <NumberCard title={"타이틀"} value={999} subValue={0.1} />
              <NumberCard title={"타이틀"} value={999} subValue={0.1} />
            </div>
          </div>
        </div>

        <div className={styles.div3}>
          <div>
            <div className={styles.div5} />
          </div>
          <div className={styles.chartContainer}>
            <div
              style={{
                marginRight: "5px",
              }}
            >
              <div className={styles.title}>설비 효율 BEST/WORST</div>
              <div className={styles.chart}>
                <BubbleChart
                  series={bubbleChartData1}
                  height={tHeight * 0.25}
                  width={cWidth * 0.2}
                />
              </div>
            </div>
            <div>
              <div className={styles.title}>전력 사용 BEST/WORST</div>
              <div className={styles.chart}>
                <BubbleChart
                  series={bubbleChartData2}
                  height={tHeight * 0.25}
                  width={cWidth * 0.2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.div1}>
          <div className={styles.title}>설비 고장/알람 현황(월간)</div>
          <div className={styles.numberCardContainer}>
            <NumberCard title={"타이틀"} value={999} />
            <NumberCard title={"타이틀"} value={999} />
            <NumberCard title={"타이틀"} value={999} />
            <NumberCard title={"타이틀"} value={999} />
            <NumberCard title={"타이틀"} value={999} />
          </div>
        </div>
        <div className={styles.div5} />
        <div className={styles.div2}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            설비 가동 상태
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className={`${styles.eqpStatus} ${styles.run}`}>기동</div>
            <div className={`${styles.eqpStatus} ${styles.stop}`}>정지</div>
            <div className={`${styles.eqpStatus} ${styles.idle}`}>다운</div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            전체 : 23/45 대
          </div>
        </div>
        <div className={styles.div5} />
        <div className={styles.div3}>
          <div className={styles.eqpsContainer}>
            <div className={styles.eqpsLeft}>
              <div className={styles.title}>
                <div>하역</div>
                <div style={{ fontSize: "14px" }}>
                  CSU : 5/11 대 | GTSU : 4/4대
                </div>
              </div>
              <div className={styles.eqpContainer}>
                {eqpList.map((eqp) => (
                  <EqpList
                    type={eqp.eqpType}
                    eqps={eqp.value}
                    openFlowModal={openFlowModal}
                    openCycleModal={openCycleModal}
                  />
                ))}
              </div>
            </div>
            <div className={styles.eqpsRight}>
              <div className={styles.title}>
                <div>반출</div>
                <div style={{ fontSize: "14px" }}>
                  B/C : 0/1대 | STACKER : 0/11 대{" "}
                </div>
              </div>
              <div className={styles.eqpContainer}>
                {eqpList.map((eqp) => (
                  <EqpList
                    type={eqp.eqpType}
                    eqps={eqp.value}
                    openFlowModal={openFlowModal}
                    openCycleModal={openCycleModal}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={styles.div5} />
          <div className={styles.eqpsContainer}>
            <div className={styles.eqpsLeft}>
              <div className={styles.title}>
                <div>반입</div>
                <div style={{ fontSize: "14px" }}>
                  RECLAIMER : 11/13 대 | B/C : 0/1대
                </div>
              </div>
              <div className={styles.eqpContainer}>
                {eqpList.map((eqp) => (
                  <EqpList
                    type={eqp.eqpType}
                    eqps={eqp.value}
                    openFlowModal={openFlowModal}
                    openCycleModal={openCycleModal}
                  />
                ))}
              </div>
            </div>
            <div className={styles.eqpsRight}>
              <div className={styles.title}>
                <div>브랜딩</div>
                <div style={{ fontSize: "14px" }}>
                  RECLAIMER : 1/4대 | STACKER : 3/4대
                </div>
              </div>
              <div className={styles.eqpContainer}>
                {eqpList.map((eqp) => (
                  <EqpList
                    type={eqp.eqpType}
                    eqps={eqp.value}
                    openFlowModal={openFlowModal}
                    openCycleModal={openCycleModal}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isCycleModal && (
        <CycleModal isCycleModal={isCycleModal} handleCancel={handleCancel} />
      )}
      {isFlowModal && (
        <FlowModal isFlowModal={isFlowModal} handleCancel={handleCancel} />
      )}
    </div>
  );
};

export default Dashboard;
