import React, {useEffect, useState} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {headerState, searchState} from "../../../store/atom";
import {useTranslation} from "react-i18next";
import {Card, Col, Descriptions, PaginationProps, Row} from "antd";
import {Table} from "antd";
import {useLocation} from "react-router-dom";
import SearchComponent from "../SearchComponent";
import DataDetailModal from "./DataDetailModal";
import service from "@services/dataShareService";
import dayjs from "dayjs";
import {ShareHistoryTransData} from "@pages/shareData/history/history";
import {ColumnType} from "antd/es/table";

  const DataCollectionHistoryDetail = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dataSharId = location.state.dataSharId;
  const [detailData] = useState<ShareHistoryTransData>(location.state);
  const [isDetail, setIsDetail] = useState<boolean>(false);

  const setStatus = (data: string) => {
    let result = "";
    switch (data) {
      case "":
        result = "전체";
        break;
      case null:
        result = "-";
        break;
      case "Y":
        result = "완료";
        break;
      case "N":
        result = "수집중";
        break;
      default:
        result = "-";
        break;
    }
    return result;
  };

  const dateCompare = (a: any, b: any) => {
    if (a === null && b === null) {
      return 0;
    }

    const unixA = dayjs(a).valueOf();
    const unixB = dayjs(b).valueOf();

    if (unixA === unixB) return 0;
    if (unixA > unixB) {
      return 1;
    } else {
      return -1;
    }
  };

  const columns: ColumnType<ShareHistoryTransData>[] = [
    { align: "right", width: "40px", title: "No.", dataIndex: "key" },
    {
      align: "left",
      title: t("main:shareData:sharedDataId"),
      dataIndex: "dataSharId",
      key: "dataSharId",
    },
    {
      align: "left",
      title: t("main:shareData:sendingCompany"),
      dataIndex: "trnsUserNm",
      key: "trnsUserNm",
    },
    {
      align: "left",
      title: t("main:shareData:sendingSystem"),
      dataIndex: "trnsSysNm",
      key: "trnsSysNm",
    },
    {
      align: "left",
      title: t("main:shareData:receivingCompany"),
      dataIndex: "rcptnUserNm",
      key: "rcptnUserNm",
    },
    {
      align: "left",
      title: t("main:shareData:receivingSystem"),
      dataIndex: "rcptnSysNm",
      key: "rcptnSysNm",
    },

    {
      align: "left",
      title: t("main:shareData:datasetName"),
      dataIndex: "datasetName",
      key: "datasetName",
    },
    {
      align: "left",
      title: t("main:shareData:datasetId"),
      dataIndex: "datasetId",
      key: "datasetId",
    },
    {
      align: "left",
      title: t("main:shareData:sendingType"),
      dataIndex: "trnsMthd",
      key: "trnsMthd",
    },
    {
      align: "center",
      title: t("main:shareData:sendingDate"),
      dataIndex: "trnsDt",
      key: "trnsDt",
      sorter: (a: any, b: any) => dateCompare(a.trnsDt, b.trnsDt),
      render: (record: any) => {
        return (
          <div>{dayjs(record).format("YYYY-MM-DD HH:mm:ss")}</div>
        );
      },
    },
    {
      align: "left",
      title: t("main:shareData:receivingType"),
      dataIndex: "rcptnMthd",
      key: "rcptnMth                                                                                                                                                                                                                                                       d",
    },
    {
      align: "center",
      title: t("main:shareData:receivingDate"),
      dataIndex: "rcptnDt",
      key: "rcptnDt",
      sorter: (a: any, b: any) => dateCompare(a.rcptnDt, b.rcptnDt),
      render: (record: any) => {
        let date = "-";
        if (record) {
          date = dayjs(record).format("YYYY-MM-DD HH:mm:ss");
        }
        return (
          <div>{date}</div>
        );
      },
    },
    {
      align: "left",
      title: t("main:shareData:receivingStatus"),
      dataIndex: "status",
      key: "status",
      render: (record: any) => {
        return (
          <div>{setStatus(record)}</div>
        );
      },
    },
  ];

  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:shareData"),
      subTitle: t("main:header:shareDataHistory"),
      subSubTitle: t("main:header:shareDataHistoryDetail"),
      path: "/shareData/history/detail",
      isGoBackVisible: true,
    });
  }, []);

  const handleOk = () => {
    setIsDetail(false);
  };

  const [searchCondition, setSearchCondition] = useRecoilState(searchState);
  const [dataList, setDataList] = useState<ShareHistoryTransData[]>();
  const [transactionId, setTransactionId] = useState<any>();
  const [modalParam, setModalParam] = useState<ShareHistoryTransData>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  useEffect(() => {
    getDataTransactionList(dataSharId);
  }, [dataSharId, size, page]);

  const getDataTransactionList = (dataSharId: string, param?: any) => {
    let obj: any = {
      trnsUserId: searchCondition.trnsUserId,
      trnsSystemId: searchCondition.trnsSystemId,
      rcptnUserId: searchCondition.rcptnUserId,
      rcptnSystemId: searchCondition.rcptnSystemId,
      status: searchCondition.status,
      datasetName: searchCondition.datasetName,
      datasetItemName: searchCondition.dataItemName,
      // startDateTime: searchCondition.startDateTime,
      // endDateTime: searchCondition.endDateTime,
      page,
      size,
    };

    if (param) {
      obj.startDateTime = param.startDateTime;
      obj.endDateTime = param.endDateTime;
    } else {
      obj.startDateTime = searchCondition.startDateTime;
      obj.endDateTime = searchCondition.endDateTime;
    }

    service
      .getDataTransactionList({
        dataSharId,
        obj,
      })
      .then((res) => {
        const data = res.data.list;
        setTotalCount(res.data.totalCount);
        const _size = res.data.pageSize;
        setSize(res.data.pageSize);
        let _r = [];

        for (let i in data) {
          const _data = {
            ...data[i],
            key: Number(i) + (obj.page - 1) * _size + 1,
          };
          _r.push(_data);
        }
        setDataList(_r);
      });
  };

  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    setPage(pageNumber);
    setSearchCondition({ ...searchCondition, page: pageNumber, size });
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = async (
    current,
    pageSize
  ) => {
    setSize(pageSize);
    setSearchCondition({ ...searchCondition, page: current, size: pageSize });
  };

  const onSearch2 = (param: any) => {
    getDataTransactionList(dataSharId, param);
  };

  return (
    <div>
      <SearchComponent period={true} onSearch2={onSearch2} isBackBtnVisible={true} />
      <Card style={{ marginTop: "10px" }}>
        <Descriptions bordered column={4}>
          <Descriptions.Item label={"No."}>
            {detailData.key}
          </Descriptions.Item>
          <Descriptions.Item label={t("main:shareData:sharedDataId")} span={3}>
            {detailData.dataSharId}
          </Descriptions.Item>
          <Descriptions.Item label={t("main:shareData:datasetName")}>
            {detailData.datasetName}
          </Descriptions.Item>
          <Descriptions.Item label={t("main:shareData:datasetId")}>
            {detailData.datasetId}
          </Descriptions.Item>
          <Descriptions.Item label={t("main:shareData:datasetUuid")} span={2}>
            {detailData.datasetUuid}
          </Descriptions.Item>
          <Descriptions.Item label={t("main:shareData:sendingCompany")}>
            {detailData.trnsUserNm}
          </Descriptions.Item>
          <Descriptions.Item label={t("main:shareData:sendingSystem")}>
            {detailData.trnsSysNm}
          </Descriptions.Item>
          <Descriptions.Item label={t("main:shareData:sendingType")} span={2}>
            {detailData.trnsMthd}
          </Descriptions.Item>
          {/*송신일자 삭제*/}
          {/*<Descriptions.Item label={t("main:shareData:sendingDate")} span={2}>*/}
          {/*  {dayjs(detailData.trnsDt).format("YYYY-MM-DD HH:mm:ss")}*/}
          {/*</Descriptions.Item>*/}
          <Descriptions.Item label={t("main:shareData:receivingCompany")}>
            {detailData.rcptnUserNm}
          </Descriptions.Item>
          <Descriptions.Item label={t("main:shareData:receivingSystem")}>
            {detailData.rcptnSysNm}
          </Descriptions.Item>
          <Descriptions.Item label={t("main:shareData:receivingType")}>
            {detailData.rcptnMthd}
          </Descriptions.Item>
          {/*수신일자, 수신상태 삭제*/}
          {/*<Descriptions.Item label={t("main:shareData:receivingDate")}>*/}
          {/*  {detailData.rcptnDt ? dayjs(detailData.rcptnDt).format("YYYY-MM-DD HH:mm:ss") : "-"}*/}
          {/*</Descriptions.Item>*/}
          {/*<Descriptions.Item label={t("main:shareData:receivingStatus")}>*/}
          {/*  {setStatus(detailData.status)}*/}
          {/*</Descriptions.Item>*/}
        </Descriptions>
      </Card>
      <Table
        className="dataShare"
        style={{ marginTop: "10px" }}
        dataSource={dataList}
        columns={columns}
        scroll={{ x: true }}
        size="small"
        pagination={{
          onShowSizeChange: onShowSizeChange,
          current: page,
          onChange: onChange,
          total: totalCount,
          defaultCurrent: 1,
          pageSize: size,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setTransactionId(record.transactionId);
              setModalParam(record);
              setIsDetail(true);
            },
          };
        }}
      />

      {isDetail && (
        <DataDetailModal
          visible={isDetail}
          handleOk={handleOk}
          title={location.state.datasetName}
          transactionId={transactionId}
          param={modalParam}
        />
      )}
    </div>
  );
};

export default DataCollectionHistoryDetail;
