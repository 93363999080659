import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { headerState } from "../../../store/atom";
import { useTranslation } from "react-i18next";
import styles from "@styles/carbon/carbonDashboard.module.css";
import { Tabs } from "antd";
import AllProcess from "./AllProcess";
import UnloadProcess from "./UnloadProcess";
import service from "@services/carbontaxService";
import moment from "moment";

const { TabPane } = Tabs;
interface iTotal {
  nowPower: number;
  accPower: number;
  eAmont1: number;
  eAmont2: number;
  eAmont3: number;
}

const CarbonDashboard = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:carbonEmissions"),
      subTitle: t("main:header:dashboard"),
      path: "/carbonTax/dashboard",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultSumData = {
    nowPower: 0,
    accPower: 0,
    eAmont1: 0,
    eAmont2: 0,
    eAmont3: 0,
  };

  const [, setClock] = useState<any>();
  const [totalProcessData, setTotalProcessData] = useState<iTotal>();

  const [emissionChartData, setEmissionChartData] = useState<any>();
  const [powerChartData, setPowerChartData] = useState<any>();
  const [accPowerChartData, setAccPowerChartData] = useState<any>();

  const [unloadSumData, setUnloadSumData] = useState<any>(defaultSumData);
  const [importSumData, setImportSumData] = useState<any>(defaultSumData);
  const [exportSumData, setExportSumData] = useState<any>(defaultSumData);
  const [blendingSumData, setBlendingSumData] = useState<any>(defaultSumData);

  const [unloadData, setUnloadData] = useState<any>();
  const [importData, setImportData] = useState<any>();
  const [exportData, setExportData] = useState<any>();
  const [blendingData, setBlendingData] = useState<any>();

  const setEmissionProcess = () => {
    service.getCurEmission().then((res: any) => {
      const results = res.data.result;
      let category: any = [];
      let data: any = [];
      for (let r of results) {
        category.push(r.processNm);
        data.push(Number(r.data.toFixed(2)));
      }
      setEmissionChartData({ category, data });
    });
  };

  const setPowerData = () => {
    service.getCurPower().then((res: any) => {
      const results = res.data.result;
      let category: any = [];
      let data: any = [];
      for (let r of results) {
        category.push(r.processNm);
        data.push(Number(r.data.toFixed(2)));
      }
      setPowerChartData({ category, data });
    });
  };

  const setAccPowerData = () => {
    service.getAccPowerData().then((res: any) => {
      const results = res.data.result;
      let category: any = [];
      let data: any = [];
      for (let r of results) {
        category.push(r.processNm);
        data.push(Number(r.data.toFixed(2)));
      }
      setAccPowerChartData({ category, data });
    });
  };

  const setEqpByBlending = (r?: any) => {
    const f = (results: any) => {
      let _processList = new Set<any>();
      for (let i of results) {
        _processList.add(i.processNm + " " + i.facNm);
      }
      const processList: string[] = Array.from(_processList);

      let eqpByProcess: any = {};

      for (let pName of processList) {
        eqpByProcess[pName] = [];
      }

      for (let r of results) {
        let pName = r.processNm + " " + r.facNm;
        eqpByProcess[pName].push(r);
      }
      setBlendingData(eqpByProcess);
    };

    if (r) {
      f(r);
      return;
    }

    service.getMonitorList("blending").then((res: any) => {
      const results = res.data.result;

      let _processList = new Set<any>();
      for (let i of results) {
        _processList.add(i.processNm + " " + i.facNm);
      }
      const processList: string[] = Array.from(_processList);

      let eqpByProcess: any = {};

      for (let pName of processList) {
        eqpByProcess[pName] = [];
      }

      for (let r of results) {
        let pName = r.processNm + " " + r.facNm;
        eqpByProcess[pName].push(r);
      }
      setBlendingData(eqpByProcess);
    });
  };

  useEffect(() => {
    setClock(moment().format("YYYY.MM.DD HH:mm:ss"));
    const it = setInterval(() => {
      setClock(moment().format("YYYY.MM.DD HH:mm:ss"));
    }, 1000);
    return () => {
      clearInterval(it);
    };
  }, []);

  const initData = async () => {
    _initData();
  };

  const _initData = async () => {
    setEmissionProcess();
    setPowerData();
    setAccPowerData();

    const monitoringResp = await service.getMonitorListAll();
    const monitoringData = monitoringResp.data.result;
    const groupByProcess = monitoringData.reduce((acc: any, cur: any) => {
      const process = cur["processNm"];
      if (!acc[process]) {
        acc[process] = [];
      }
      acc[process].push(cur);
      return acc;
    }, {});

    setEqpByBlending(groupByProcess["blending"]);
    exportDataProcess(groupByProcess["exporting"]);
    importDataProcess(groupByProcess["importing"]);
    unloadDataProcess(groupByProcess["unloading"]);
    monitoringDataProcess(monitoringData);
  };

  const exportDataProcess = (r?: any) => {
    const getExportSumData = (results: any) => {
      let _eData = {
        nowPower: 0,
        accPower: 0,
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
      };
      for (let result of results) {
        _eData["nowPower"] += result.fuelUsage;
        _eData["accPower"] += result.eSumAmount;
        _eData["eAmont1"] += result.eAmont1;
        _eData["eAmont2"] += result.eAmont2;
        _eData["eAmont3"] += result.eAmont3;
      }
      setExportSumData({ ..._eData });
    };

    const setEqpByExport = (results: any) => {
      let _processList = new Set<any>();
      for (let i of results) {
        _processList.add(i.processNm + " " + i.facNm);
      }
      const processList: string[] = Array.from(_processList);

      let eqpByProcess: any = {};

      for (let pName of processList) {
        eqpByProcess[pName] = [];
      }

      for (let r of results) {
        let pName = r.processNm + " " + r.facNm;
        eqpByProcess[pName].push(r);
      }
      setExportData(eqpByProcess);
    };

    if (r) {
      getExportSumData(r);
      setEqpByExport(r);
      return;
    }

    service.getMonitorList("export").then((res: any) => {
      const results = res.data.result;
      getExportSumData(results);
      setEqpByExport(results);
    });
  };

  const importDataProcess = (r?: any) => {
    const getImportSumData = (results: any) => {
      let _eData = {
        nowPower: 0,
        accPower: 0,
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
      };
      for (let result of results) {
        _eData["nowPower"] += result.fuelUsage;
        _eData["accPower"] += result.eSumAmount;
        _eData["eAmont1"] += result.eAmont1;
        _eData["eAmont2"] += result.eAmont2;
        _eData["eAmont3"] += result.eAmont3;
      }
      setImportSumData({ ..._eData });
    };

    const setEqpByImport = (results: any) => {
      let _processList = new Set<any>();
      for (let i of results) {
        _processList.add(i.processNm + " " + i.facNm);
      }
      const processList: string[] = Array.from(_processList);

      let eqpByProcess: any = {};

      for (let pName of processList) {
        eqpByProcess[pName] = [];
      }

      for (let r of results) {
        let pName = r.processNm + " " + r.facNm;
        eqpByProcess[pName].push(r);
      }

      setImportData(eqpByProcess);
    };

    if (r) {
      getImportSumData(r);
      setEqpByImport(r);
      return;
    }

    service.getMonitorList("import").then((res: any) => {
      const results = res.data.result;
      getImportSumData(results);
      setEqpByImport(results);
    });
  };

  const unloadDataProcess = (r?: any) => {
    const getUnloadSumData = (results: any) => {
      let _eData = {
        nowPower: 0,
        accPower: 0,
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
      };
      for (let result of results) {
        _eData["nowPower"] += result.fuelUsage;
        _eData["accPower"] += result.eSumAmount;
        _eData["eAmont1"] += result.eAmont1;
        _eData["eAmont2"] += result.eAmont2;
        _eData["eAmont3"] += result.eAmont3;
      }
      setUnloadSumData({ ..._eData });
    };

    const getBlendingSumData = (results: any) => {
      let _eData = {
        nowPower: 0,
        accPower: 0,
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
      };
      for (let result of results) {
        _eData["nowPower"] += result.fuelUsage;
        _eData["accPower"] += result.eSumAmount;
        _eData["eAmont1"] += result.eAmont1;
        _eData["eAmont2"] += result.eAmont2;
        _eData["eAmont3"] += result.eAmont3;
      }
      setBlendingSumData({ ..._eData });
    };

    const setEqpByUnload = (results: any) => {
      const processList = ["unloading CSU", "unloading GTSU"];
      let eqpByProcess: any = {};

      for (let pName of processList) {
        eqpByProcess[pName] = [];
      }

      for (let r of results) {
        let pName = r.processNm + " " + r.facNm;
        eqpByProcess[pName].push(r);
      }

      setUnloadData(eqpByProcess);
    };

    if (r) {
      getUnloadSumData(r);
      getBlendingSumData(r);
      setEqpByUnload(r);
      return;
    }

    service.getMonitorList("unload").then((res: any) => {
      const results = res.data.result;
      getUnloadSumData(results);
      getBlendingSumData(results);
      setEqpByUnload(results);
    });
  };

  const monitoringDataProcess = (r?: any) => {
    const setSumByProcess = (results: any) => {
      let _eData = {
        nowPower: 0,
        accPower: 0,
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
      };

      for (let result of results) {
        _eData["nowPower"] += result.fuelUsage;
        _eData["accPower"] += result.eSumAmount;
        _eData["eAmont1"] += result.eAmont1;
        _eData["eAmont2"] += result.eAmont2;
        _eData["eAmont3"] += result.eAmont3;
      }
      setTotalProcessData(_eData);
    };

    if (r) {
      setSumByProcess(r);
      return;
    }

    service.getMonitorListAll().then((res: any) => {
      const results = res.data.result;
      setSumByProcess(results);

      // const groupByProcess = results.reduce((acc: any, cur: any) => {
      //   const process = cur["processNm"];
      //   if (!acc[process]) {
      //     acc[process] = [];
      //   }
      //   acc[process].push(cur);
      //   return acc;
      // }, {});
    });
  };

  useEffect(() => {
    let it: any;
    const intervalAPIs = () => {
      it = setInterval(() => {
        initData();
      }, 1000 * 10); //10초에 한번씩 호출
    };

    initData(); // 한번 호출
    let to = setTimeout(intervalAPIs, 1000 * 20); // 20초 뒤 호출

    return () => {
      if (to) {
        clearTimeout(to);
      }

      if (it) {
        clearInterval(it);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.template}>
      <Tabs id="carbon" type="card" defaultActiveKey="1">
        <TabPane tab="All" key="1">
          <AllProcess
            key={"all"}
            totalProcessData={totalProcessData}
            emissionChartData={emissionChartData}
            powerChartData={powerChartData}
            accPowerChartData={accPowerChartData}
            unloadSumData={unloadSumData}
            importSumData={importSumData}
            exportSumData={exportSumData}
            blendingSumData={blendingSumData}
            unloadData={unloadData}
            importData={importData}
            exportData={exportData}
            blendingData={blendingData}
          />
        </TabPane>
        <TabPane tab="Unload" key="2">
          <UnloadProcess
            key={"unload"}
            unloadSumData={unloadSumData}
            unloadData={unloadData}
          />
        </TabPane>
        <TabPane tab="Import" key="3"></TabPane>
        <TabPane tab="Export" key="4"></TabPane>
        <TabPane tab="Blending" key="5"></TabPane>
      </Tabs>
    </div>
  );
};

export default CarbonDashboard;
