import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select } from "antd";

const ModifyModal = ({ visible, handleCancel, handleOk, obj }: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...obj,
    });
  }, []);

  const onFinishForm = (value: any) => {
    handleOk(value);
  };

  const [title, setTitle] = useState<string>();
  useEffect(() => {
    if (!obj) {
      setTitle("배출 데이터 추가");
    } else {
      setTitle("배출 데이터 수정");
    }
  }, []);

  const validateMessages = {
    required: "'${label}'을 입력하세요.",
  };

  return (
    <Modal
      className="common"
      visible={visible}
      title={title}
      open={visible}
      onOk={form.submit}
      onCancel={handleCancel}
      okText="저장"
      cancelText="취소"
    >
      <Form
        labelCol={{ span: 7, offset: 1 }}
        wrapperCol={{ span: 11, offset: 1 }}
        form={form}
        onFinish={onFinishForm}
        validateMessages={validateMessages}
      >
        <Form.Item name="coEfficentId" label="id" hidden={true} />
        <Form.Item name="process" label="공정">
          <Select />
        </Form.Item>
        <Form.Item name="type" label="타입">
          <Select />
        </Form.Item>
        <Form.Item
          name="facilityNm"
          label="내부 시설/장비 명"
          rules={[{ required: true }]}
        >
          <Input type="text"></Input>
        </Form.Item>
        <Form.Item name="uncertainty" label="불확도">
          <Input type="number"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModifyModal;
