import axios from "axios";
import { SearchPayload } from "@type/enum/enum";

// import { SearchPayload, SearchPayloadNoDate } from "../pages/cal/EmissionsCal";
// import { AddFactorPayload } from "../pages/management/Emissionstatusinfo";

const apiUrl = process.env.REACT_APP_VCP_API_URL;

const carbontaxService = {
  /**
   * @decs 모니터링 전체 조회
   * @returns
   */
  async getMonitorListAll() {
    const response = await axios.get(`${apiUrl}/Monitor/MonitorAll`);
    return response;
    // return data.MonitorListAll0;
  },

  /**
   * @desc 모니터링 조건 조회
   * @param processNm 공정명
   * @returns
   */
  async getMonitorList(processNm: String) {
    const response = await axios.get(
      `${apiUrl}/Monitor/MonitorList?processNm=${processNm}`
    );
    // const response = { ...data.MonitorListAll0 };
    return response;
    // return data.MonitorListAll0;
  },

  /**
   * @desc 전력량 조희
   * @returns
   */
  async getCurPower() {
    const response = await axios.get(`${apiUrl}/Monitor/curPower`);
    return response;
  },

  /**
   * @desc 공정별 누적 전력량 조회
   * @returns
   */
  async getAccPowerData() {
    const response = await axios.get(`${apiUrl}/Monitor/accumPower`);
    return response;
  },

  /**
   * @desc 전력 추이 조회
   * @returns
   */
  async getElectricPowerTrend() {
    const response = await axios.get(`${apiUrl}/Trend/ElectricPower`);
    return response;
  },

  /**
   * @desc CO2 배출량 추이 조회
   * @returns
   */
  async getCarbonGasTrend(processNm: String) {
    const response = await axios.get(
      `${apiUrl}/Trend/CO2?processNm=${processNm}`
    );
    return response;
  },

  /**
   * @desc 배출원 정보 조회
   * @returns
   */
  async getEmission() {
    const response = await axios.get(`${apiUrl}/Emission/Emission`);
    return response;
  },

  /**
   * @desc 배출원 정보 추가/수정
   * @param payload
   * @returns
   */
  async getEmissionUpdate(payload: any) {
    let result = { ...payload };

    if (payload.emissionId === undefined) {
      result = {
        facilityNm: payload.facilityNm,
        process: payload.process,
        type: payload.type,
        uncertainty: payload.uncertainty,
      };
    }

    const response = await axios.post(
      `${apiUrl}/Emission/Emission/EmissionUpdate`,
      result
    );
    return response;
  },

  /**
   * @desc 배출원 정보 삭제
   * @param payload
   * @returns
   */
  async getEmissionDelete(payload: String) {
    const response = await axios.get(
      `${apiUrl}/Emission/Emission/EmissionDelete?emissionId=${payload}`
    );
    return response;
  },

  /**
   * @desc 배출계수 정보 조회
   * @returns
   */
  async getCoefficent() {
    const response = await axios.get(`${apiUrl}/Emission/Coefficent`);
    return response;
  },

  /**
   * @desc 배출계수 정보 등록/수정
   * @returns
   */
  async getCoefficentUpdate(payload: any) {
    let result = { ...payload };

    if (payload.coefficentId === undefined) {
      result = {
        activeData: payload.activeData,
        category: payload.category,
        emissionActive: payload.emissionActive,
        emissionActiveType: payload.emissionActiveType,
        emissionFactor: payload.emissionFactor,
        formula: payload.formula,
        scope: payload.scope,
        standard: payload.standard,
        tier: payload.tier,
        version: payload.version,
      };
    }

    const response = await axios.post(
      `${apiUrl}/Emission/CoefficentUpdate`,
      result
    );
    return response;
  },

  /**
   * @desc 배출계수 정보 삭제
   * @returns
   */
  async getCoefficentDelete(payload: String) {
    const response = await axios.get(
      `${apiUrl}/Emission/CoefficentDelete?coefficentId=${payload}`
    );
    return response;
  },

  // /**
  //  * @desc 배출원/계수 연관 디테일 정보 수정
  //  * @returns
  //  */
  // async getRelationDetailUpdate(payload: any) {
  //   const response = await axios.post(
  //     `${apiUrl}/Emission/RelationDetailUpdate`,
  //     payload
  //   );
  //   return response;
  // },

  // /**
  //  * @desc 배출원/계수 연관 정보 수정
  //  * @param payload
  //  * @returns
  //  */
  // async getRelationUpdate(payload: any) {
  //   const response = await axios.post(
  //     `${apiUrl}/Emission/RelationUpdate`,
  //     payload
  //   );
  //   return response;
  // },

  /**
   * @desc 환산 계수 리스트 조회
   * @returns
   */
  async getCoFactorList() {
    const response = await axios.post(`${apiUrl}/Factor/ConversionDetail`);
    return response;
  },

  // http://localhost:8088/api/Emission/RelationDetailUpdate

  //  배출량 산정 정보
  async getEmissionDesc(payload: SearchPayload) {
    const response = await axios.post(
      `${apiUrl}/Emission/EmissionDesc`,
      payload
    );
    return response;
  },

  async getEmissionCheck(payload: SearchPayload) {
    const response = await axios.post(
      `${apiUrl}/Emission/EmissionCheck`,
      payload
    );
    return response;
  },

  async getCarbonTrend() {
    const response = await axios.get(`${apiUrl}/Trend/CarbonTax`);
    return response;
  },

  /**
   * @desc trendByProcess Data
   */
  trendByProcessData: {
    status: 200,
    data: {
      // result: dummyData.processTrend.chartData.chart1.data,
      result: [
        //prettier-ignore
        {          
          "name": "unload",
          "data": [
            85.7439, 67.7919, 99.082, 5.0315, 24.1208, 68.8983, 1.4565,
            87.3034, 67.1302, 70.5106, 99.7444, 64.4485, 48.2178, 65.3333,
            84.7975, 53.2355, 26.4019, 87.9736, 95.7151, 50.4357, 28.3453,
            5.4155, 52.0278, 71.699,
          ],
        },
        {
          name: "import",
          data: [
            14.792, 22.993, 33.8174, 46.7226, 86.9582, 88.147, 75.1345, 46.2909,
            41.54, 37.4839, 76.3255, 52.6264, 67.0064, 28.132, 30.4551, 50.7236,
            49.9812, 78.8929, 54.2333, 29.2529, 21.392, 24.7912, 81.8355,
            78.791,
          ],
        },
        {
          name: "export",
          data: [
            49.6275, 81.1803, 17.6014, 97.9223, 70.6703, 25.6558, 19.6514,
            1.136, 48.4306, 44.1177, 15.8395, 32.2029, 32.2703, 61.5239,
            73.9731, 90.1855, 82.5263, 77.2229, 99.7847, 29.4165, 75.4148,
            2.5118, 47.2073, 12.1652,
          ],
        },
        {
          name: "blending",
          data: [
            59.5447, 23.3009, 61.065, 70.0033, 32.6558, 54.3453, 26.4241,
            91.5388, 28.7682, 3.99, 49.6536, 10.1852, 83.2253, 85.5742, 78.1366,
            27.335, 32.9341, 52.2604, 97.866, 22.1991, 93.1125, 95.6911,
            69.7726, 32.9207,
          ],
        },
      ],
    },
  },
  /**
   * @desc 공정별 배출량 변동추이 조회 (CO2)
   * @returns
   */
  async getCO2TrendByProcess(payload: SearchPayload) {
    const response = await axios.post(`${apiUrl}/TrendByProcess/CO2`, payload);
    return response;
  },
  /**
   * @desc 공정별 배출량 변동추이 조회 (N2O)
   * @returns
   */
  async getN2OTrendByProcess(payload: SearchPayload) {
    const response = await axios.post(`${apiUrl}/TrendByProcess/N2O`, payload);
    return response;
  },
  /**
   * @desc 공정별 배출량 변동추이 조회 (CH4)
   * @returns
   */
  async getCH4TrendByProcess(payload: SearchPayload) {
    const response = await axios.post(`${apiUrl}/TrendByProcess/CH4`, payload);
    return response;
  },

  async getCurEmission() {
    const response = await axios.get(`${apiUrl}/Monitor/curEmission`);
    return response;
  },

  async getModelTree() {
    const response = await axios.get("https://aipim.synology.me:4321/api/tree");
    return response;
  },

  async getAAS() {
    const response = await axios.get("https://aipim.synology.me:4321/api/aas");
    return response;
  },

  async getMethaneGasTrend(processNm: String) {
    const response = await axios.get(
      `${apiUrl}/Trend/CH4?processNm=${processNm}`
    );
    return response;
  },

  async getNitrogenDioxideTrend(processNm: String) {
    const response = await axios.get(
      `${apiUrl}/Trend/N2O?processNm=${processNm}`
    );
    return response;
  },

  async getCurEmissionDetail(processNm: String) {
    const response = await axios.get(
      `${apiUrl}/Monitor/curEmissionDetail?processNm=${processNm}`
    );

    return response;
  },

  async getCurPowerDetail(processNm: String) {
    const response = await axios.get(
      `${apiUrl}/Monitor/curPowerDetail?processNm=${processNm}`
    );

    return response;
  },

  async getAccumPowerDetail(processNm: String) {
    const response = await axios.get(
      `${apiUrl}/Monitor/accumPowerDetail?processNm=${processNm}`
    );

    return response;
  },

  async getExcelExport(payload: object, filePath: String) {
    const response = await axios.post(
      `${apiUrl}/Emission/ExcelExport?filePath=${filePath}`,
      payload
    );

    return response;
  },

  async getTrace() {
    const response = await axios.get(`${apiUrl}/TempTrace`);
    return response;
  },

  async getProductInfo(payload: string) {
    const response = await axios.get(
      `${apiUrl}/TempReport/ProductInfo?lotId=${payload}`
    );

    return response;
  },
  async getAllProcessGross(payload: string) {
    const response = await axios.get(
      `${apiUrl}/TempReport/AllProcessGross?lotId=${payload}`
    );
    return response;
  },
  async getProcessGross(payload: string) {
    const response = await axios.get(
      `${apiUrl}/TempReport/ProcessGross?lotId=${payload}`
    );
    return response;
  },
  async getTraceabilityInfo(payload: string) {
    const response = await axios.get(
      `${apiUrl}/TempReport/TraceabilityInfo?lotId=${payload}`
    );
    return response;
  },

  async getEmissionFactor(payload: any) {
    const response = await axios.get(
      `${apiUrl}/emission-factor?scope=${payload.scope}&activityScope=${payload.activityScope}&activity=${payload.activity}&source=${payload.source}&type=${payload.type}`
    );

    return response;
  },

  async getTypeHeader(payload: string) {
    const response = await axios.get(
      `${apiUrl}/emission-factor/headers?type=${payload}`
    );
    return response;
  },

  async createEmissionFactor(payload: any) {
    const response = await axios.post(`${apiUrl}/emission-factor`, payload);
    return response;
  },

  async updateEmissionFactor(payload: any) {
    const response = await axios.post(
      `${apiUrl}/emission-factor/${payload.id}`,
      payload
    );
    return response;
  },

  async deleteEmissionFactor(payload: any) {
    const response = await axios.delete(`${apiUrl}/emission-factor/${payload}`);
    return response;
  },

  async getEmissionFactorMapping(payload: any) {
    const response = await axios.get(
      `${apiUrl}/emission-factor-mapping?scope=${payload.scope}&activityScope=${payload.activityScope}&activity=${payload.activity}&source=${payload.source}&type=${payload.type}`
    );
    return response;
  },

  async getEmissionFactorMappingByEqp() {
    const response = await axios.get(`${apiUrl}/emission-factor-mapping/eqp`);
    return response;
  },

  async getEmissionFactorMappingByFactor(payload: any) {
    const response = await axios.get(
      `${apiUrl}/emission-factor-mapping/factor?scope=${payload.scope}&activityScope=${payload.activityScope}&activity=${payload.activity}&source=${payload.source}&type=${payload.type}`
    );
    return response;
  },

  async updateEmissionFactorMapping(payload: any) {
    const response = await axios.post(
      `${apiUrl}/emission-factor-mapping`,
      payload
    );
    return response;
  },

  async updateEmissionFactorMappingDetail(payload: any) {
    const response = await axios.post(
      `${apiUrl}/emission-factor-mapping/${payload.id}`,
      payload
    );
    return response;
  },
};

export default carbontaxService;
