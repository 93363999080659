import React, { useEffect, useState } from "react";
import service from "@services/carbontaxService";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "@styles/carbon/unloadProcess.module.css";
import ProcessSum from "./ProcessSum";
import SumComponent from "./SumComponent";
import EqpList from "./EqpList";
import ChartModal from "./ChartModal";
import EqpChartModal from "./EqpChartModal";
import HorizontalBarChart from "./chart/BarChart";

const loadingIcon = (
  <LoadingOutlined style={{ fontSize: 48, color: "gray" }} spin />
);

const csuDummyList = [
  {
    eqpType: "CSU",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU101",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU102",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU103",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU104",
        facNm: "CSU",
        status: "idle",
      },
    ],
  },
  {
    eqpType: "CSU",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU105",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU106",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU107",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU108",
        facNm: "CSU",
        status: "idle",
      },
    ],
  },
  {
    eqpType: "CSU",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU111",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU109",
        facNm: "CSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "CSU110",
        facNm: "CSU",
        status: "idle",
      },
      undefined,
    ],
  },
];

const gtsuDummyList = [
  {
    eqpType: "GTSU",
    value: [
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "GTSU101",
        facNm: "GTSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "GTSU102",
        facNm: "GTSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "GTSU103",
        facNm: "GTSU",
        status: "idle",
      },
      {
        eAmont1: 0,
        eAmont2: 0,
        eAmont3: 0,
        eqpName: "GTSU104",
        facNm: "GTSU",
        status: "idle",
      },
    ],
  },
];

const dummy = {
  nowPower: 0,
  accPower: 0,
  eAmont1: 0,
  eAmont2: 0,
  eAmont3: 0,
};

const UnloadProcess = ({ unloadSumData, unloadData }: any) => {
  const [isChart, setIsChart] = useState<boolean>(false);
  const [isEqpChart, setIsEqpChart] = useState<any>(false);
  const [eqpName, setEqpName] = useState<any>(false);
  const [unloadEqpSumData, setUnloadEqpSumData] = useState<any>();
  const [emissionChart, setEmissionChart] = useState<any>();
  const [powerChart, setPowerChart] = useState<any>();
  const [accPowerChart, setAccPowerChart] = useState<any>();

  const [csuList, setCsuList] = useState<any>();
  const [gtsuList, setGtsuList] = useState<any>();

  useEffect(() => {
    let csu: any = [];
    if (!unloadData) return;
    for (let index = 0; index < 3; index++) {
      let value = [];
      for (let i = 0; i < 4; i++) {
        const data = unloadData["unloading CSU"][index * 4 + i];
        value.push(data);
      }
      csu.push({ eqpType: "csu", value });
    }
    setCsuList(csu);

    let gtsu: any = [];
    let value = [];
    for (let index = 0; index < 4; index++) {
      const data = unloadData["unloading GTSU"][index];
      value.push(data);
    }
    gtsu.push({ eqpType: "gtsu", value });
    setGtsuList(gtsu);
  }, [unloadData]);

  useEffect(() => {
    getCurEmission();
    getCurPower();
    getAccumPowerDetail();
  }, []);

  const getCurEmission = () => {
    service.getCurEmissionDetail("unloading").then((res) => {
      const results = res.data.result;
      let category: any = [];
      let data: any = [];
      for (let r of results) {
        category.push(r.eqpNm);
        data.push(Number(r.data.toFixed(2)));
      }
      setEmissionChart({ category, data });
    });
  };

  const getCurPower = () => {
    service.getCurPowerDetail("unloading").then((res) => {
      const results = res.data.result;
      let category: any = [];
      let data: any = [];
      for (let r of results) {
        category.push(r.eqpNm);
        data.push(Number(r.data.toFixed(2)));
      }
      setPowerChart({ category, data });
    });
  };

  const getAccumPowerDetail = () => {
    service.getAccumPowerDetail("unloading").then((res) => {
      const results = res.data.result;
      let category: any = [];
      let data: any = [];
      for (let r of results) {
        category.push(r.eqpNm.split("-")[1]);
        data.push(Number(r.data.toFixed(2)));
      }
      setAccPowerChart({ category, data });
    });
  };

  const showChartModal = () => {
    setIsChart(true);
  };

  const handleOk = () => {
    setIsChart(false);
    setIsEqpChart(false);
  };

  const getUnloadEqpSumData = () => {
    service.getMonitorList("unload").then((res) => {
      const results = res.data.result;
      const facList = ["CSU", "GTSU"];

      let _eData: any = [];
      for (let facNm of facList) {
        _eData[facNm] = {
          nowPower: 0,
          accPower: 0,
          eAmont1: 0,
          eAmont2: 0,
          eAmont3: 0,
        };
      }

      for (let result of results) {
        let pName = result.facNm;
        _eData[pName].nowPower += result.fuelUsage;
        _eData[pName]["accPower"] += result.eSumAmount;
        _eData[pName]["eAmont1"] += result.eAmont1;
        _eData[pName]["eAmont2"] += result.eAmont2;
        _eData[pName]["eAmont3"] += result.eAmont3;
      }

      setUnloadEqpSumData(_eData);
    });
  };

  useEffect(() => {
    getUnloadEqpSumData();
  }, []);

  return (
    <div className={styles.template}>
      <div className={styles.div1}>
        <div className={styles.processsumContainer}>
          {!unloadEqpSumData ? (
            <ProcessSum type={"unload"} data={dummy} title={"CSU"} />
          ) : (
            <ProcessSum
              type={"unload"}
              data={unloadEqpSumData["CSU"]}
              title={"CSU"}
            />
          )}
          {!unloadEqpSumData ? (
            <ProcessSum type={"unload"} data={dummy} title={"GTSU"} />
          ) : (
            <ProcessSum
              type={"unload"}
              data={unloadEqpSumData["GTSU"]}
              title={"GTSU"}
            />
          )}
        </div>
        <div className={styles.sumContainer}>
          <SumComponent
            tData={unloadSumData || undefined}
            showChartModal={showChartModal}
          />
        </div>
      </div>
      <div className={styles.div2}>
        <div className={styles.processContainer}>
          <div className={styles.row1}>
            <div>
              <div className={styles.title}>CSU</div>
              {!csuList
                ? csuDummyList.map((eqp: any) => (
                    <EqpList
                      category={"unload"}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))
                : csuList.map((eqp: any) => (
                    <EqpList
                      category={"unload"}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))}
            </div>
          </div>
          <div className={styles.row1}>
            <div>
              <div className={styles.title}>GTSU</div>
              {!gtsuList
                ? gtsuDummyList.map((eqp: any) => (
                    <EqpList
                      category={"unload"}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))
                : gtsuList.map((eqp: any) => (
                    <EqpList
                      category={"unload"}
                      type={eqp.eqpType}
                      eqps={eqp.value}
                      setIsEqpChart={setIsEqpChart}
                      setEqpName={setEqpName}
                    />
                  ))}
            </div>
          </div>
        </div>
        <div className={styles.chartContainer}>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {emissionChart ? (
              <HorizontalBarChart
                title={"Emissions by Process"}
                data={emissionChart["data"]}
                category={emissionChart["category"]}
              />
            ) : (
              loadingIcon
            )}
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {powerChart ? (
              <HorizontalBarChart
                title={"Amount of Electricity by Process"}
                data={powerChart["data"]}
                category={powerChart["category"]}
              />
            ) : (
              loadingIcon
            )}
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {accPowerChart ? (
              <HorizontalBarChart
                title={"Accumulated Power by Process"}
                data={accPowerChart["data"]}
                category={accPowerChart["category"]}
              />
            ) : (
              loadingIcon
            )}
          </div>
        </div>
      </div>
      {isChart && (
        <ChartModal
          isModalOpen={isChart}
          handleOk={handleOk}
          processNm={"unloading"}
        />
      )}
      {isEqpChart && (
        <EqpChartModal
          isModalOpen={isEqpChart}
          handleOk={handleOk}
          eqpName={eqpName}
        />
      )}
    </div>
  );
};

export default UnloadProcess;
