import React, { useEffect } from "react";
import { Modal, Form, Input } from "antd";

const ModifyModal = ({ visible, handleCancel, handleOk, obj }: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...obj,
    });
  }, []);

  const onFinishForm = (value: any) => {
    handleOk(value);
  };

  return (
    <Modal
      className="common"
      visible={visible}
      title={"배출원/계수 연관 데이터 수정"}
      open={visible}
      onOk={form.submit}
      onCancel={handleCancel}
      okText="저장"
      cancelText="취소"
    >
      <Form
        labelCol={{ span: 7, offset: 1 }}
        wrapperCol={{ span: 11, offset: 1 }}
        form={form}
        onFinish={onFinishForm}
      >
        <Form.Item name="id" label="id" hidden={true}>
          <Input type="text"></Input>
        </Form.Item>

        <Form.Item name="ucl" label="상한">
          <Input type="number"></Input>
        </Form.Item>
        <Form.Item name="lcl" label="하한">
          <Input type="number"></Input>
        </Form.Item>
        <Form.Item name="target" label="target">
          <Input type="number"></Input>
        </Form.Item>
        <Form.Item name="uncertainty" label="불확도">
          <Input type="number"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModifyModal;
