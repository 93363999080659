import React from 'react';
import {Navigate} from 'react-router-dom';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const isLogin = !!localStorage.getItem('refreshToken');

  // 로그인 되어 있지 않다면 로그인 페이지로 이동
  if (!isLogin) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;