import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { headerState } from "../../../store/atom";
import { useTranslation } from "react-i18next";
import service from "@services/carbontaxService";
import TraceChartModal from "./TraceChartModal";
import moment from "moment";
import { Table } from "antd";

const columns = [
  {
    title: "COIL ID",
    dataIndex: "coilId",
    key: "coilId",
  },
  {
    title: "LOT ID",
    dataIndex: "lotId",
    key: "lotId",
  },
  {
    title: "SHIP",
    children: [
      {
        title: "SHIP ID",
        dataIndex: "shipId",
        filters: [
          {
            text: "S000001",
            value: "S000001",
          },
          {
            text: "S000002",
            value: "S000002",
          },
          {
            text: "S000003",
            value: "S000003",
          },
        ],
        onFilter: (value: any, record: any) => record.shipId.includes(value),
      },
      {
        title: "SHIP CAPA",
        dataIndex: "shipCapa",
        key: "shipCapa",
      },
    ],
  },
  {
    title: "UNLOADER",
    children: [
      {
        title: "NAME",
        dataIndex: "unloaderName",
        key: "unloaderName",
      },
      {
        title: "CAPA",
        dataIndex: "unloaderCapa",
        key: "unloaderCapa",
      },
      {
        title: "SOURCE",
        dataIndex: "unloaderSource",
        key: "unloaderSource",
      },
      {
        title: "VALUE",
        dataIndex: "unloaderValue",
        key: "unloaderValue",
      },
      {
        title: "EMISSION",
        dataIndex: "unloaderEmission",
        key: "unloaderEmission",
      },
      {
        title: "TARGET",
        dataIndex: "unloaderTarget",
        key: "unloaderTarget",
      },
      {
        title: "WORK",
        dataIndex: "unloaderWork",
        key: "unloaderWork",
      },
      {
        title: "LOSS",
        dataIndex: "unloaderLoss",
        key: "unloaderLoss",
      },
      {
        title: "START",
        dataIndex: "unloaderStart",
        key: "unloaderStart",
        render: (value: any) => {
          return moment(value).format("YYYY-MM-DD hh:mm:ss");
        },
      },
      {
        title: "END",
        dataIndex: "unloaderEnd",
        key: "unloaderEnd",
        render: (value: any) => {
          return moment(value).format("YYYY-MM-DD hh:mm:ss");
        },
      },
    ],
  },
  {
    title: "BELT CONVEYOR",
    children: [
      {
        title: "NAME",
        dataIndex: "bcName",
        key: "bcName",
      },
      {
        title: "CAPA",
        dataIndex: "bcCapa",
        key: "bcCapa",
      },
      {
        title: "SOURCE",
        dataIndex: "bcSource",
        key: "bcSource",
      },
      {
        title: "VALUE",
        dataIndex: "bcValue",
        key: "bcValue",
      },
      {
        title: "EMISSION",
        dataIndex: "bcEmission",
        key: "bcEmission",
      },
      {
        title: "TARGET",
        dataIndex: "bcTarget",
        key: "bcTarget",
      },
      {
        title: "WORK",
        dataIndex: "bcWork",
        key: "bcWork",
      },
      {
        title: "LOSS",
        dataIndex: "bcLoss",
        key: "bcLoss",
      },
      {
        title: "START",
        dataIndex: "bcStart",
        key: "bcStart",
        render: (value: any) => {
          return moment(value).format("YYYY-MM-DD hh:mm:ss");
        },
      },
      {
        title: "END",
        dataIndex: "bcEnd",
        key: "bcEnd",
        render: (value: any) => {
          return moment(value).format("YYYY-MM-DD hh:mm:ss");
        },
      },
    ],
  },
  {
    title: "STACKER",
    children: [
      {
        title: "NAME",
        dataIndex: "stName",
        key: "stName",
      },
      {
        title: "CAPA",
        dataIndex: "stCapa",
        key: "stCapa",
      },
      {
        title: "SOURCE",
        dataIndex: "stSource",
        key: "stSource",
      },
      {
        title: "VALUE",
        dataIndex: "stValue",
        key: "stValue",
      },
      {
        title: "EMISSION",
        dataIndex: "stEmission",
        key: "stEmission",
      },
      {
        title: "TARGET",
        dataIndex: "stTarget",
        key: "stTarget",
      },
      {
        title: "WORK",
        dataIndex: "stWork",
        key: "stWork",
      },
      {
        title: "LOSS",
        dataIndex: "stLoss",
        key: "stLoss",
      },
      {
        title: "START",
        dataIndex: "stStart",
        key: "stStart",
        render: (value: any) => {
          return moment(value).format("YYYY-MM-DD hh:mm:ss");
        },
      },
      {
        title: "END",
        dataIndex: "stEnd",
        key: "stEnd",
        render: (value: any) => {
          return moment(value).format("YYYY-MM-DD hh:mm:ss");
        },
      },
    ],
  },
  {
    title: "YARD",
    children: [
      {
        title: "NAME",
        dataIndex: "yardName",
        key: "yardName",
      },
      {
        title: "MATERIAL",
        dataIndex: "yardMaterial",
        key: "yardMaterial",
      },
      {
        title: "START",
        dataIndex: "yardStart",
        key: "yardStart",
        render: (value: any) => {
          return moment(value).format("YYYY-MM-DD hh:mm:ss");
        },
      },
      {
        title: "END",
        dataIndex: "yardEnd",
        key: "yardEnd",
        render: (value: any) => {
          return moment(value).format("YYYY-MM-DD hh:mm:ss");
        },
      },
    ],
  },
];

const Traceability = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:carbonEmissions"),
      subTitle: t("main:header:traceability"),
      path: "/carbonTax/report",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [traceData, setTraceData] = useState<any>(null);
  const [isChart, setIsChart] = useState<boolean>(false);

  const setTraceabilitydata = () => {
    service.getTrace().then((res) => {
      let _result = [];
      const results = res.data.traces;
      for (let idx in results) {
        let d = { ...results[idx], coilId: "COIL001", key: idx, id: idx };
        _result.push(d);
      }
      setTraceData(_result);
    });
  };

  useEffect(() => {
    setTraceabilitydata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOk = () => {
    setIsChart(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ flex: "1", overflowY: "auto" }}>
        <Table
          style={{ whiteSpace: "nowrap" }}
          id="traceTable"
          dataSource={traceData}
          rowKey="serialNum"
          pagination={false}
          columns={columns}
          onRow={(record: any) => {
            if (record.shipCapa === record.unloaderValue * 1000) {
              return {
                onClick: (event) => setIsChart(true),
              };
            }
          }}
          rowClassName={(record: any) => {
            if (
              record.shipCapa === record.unloaderValue * 1000 ||
              record.shipCapa < record.unloaderValue * 1000
            ) {
              return "importing-color";
            } else {
              return "";
            }
          }}
        ></Table>
      </div>
      {isChart && <TraceChartModal isModalOpen={isChart} handleOk={handleOk} />}
    </div>
  );
};

export default Traceability;
