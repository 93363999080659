import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const DonutChart = ({ title, data, category }: any) => {
  const [series, setSeries] = React.useState<ApexAxisChartSeries>([]);
  const [uCategory, setUCategory] = useState<any>();

  const chartOptions: ApexOptions = {
    labels: uCategory,
    plotOptions: {
      pie: {
        offsetX: 0,
        offsetY: 0,
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              color: "#FFFFFF",
              offsetY: 25,
            },
            value: {
              show: true,
              fontSize: "20px",
              fontFamily: "Noto Sans KR",
              fontWeight: 400,
              offsetY: -20,
              color: "#FFFFFF",
            },
            total: {
              show: true,
              fontSize: "14x",
              fontFamily: "Noto Sans KR",
              fontWeight: 400,
              color: "#FFFFFF",
              formatter: function (w) {
                var total = w.globals.seriesTotals.reduce((a: any, b: any) => {
                  return a + b;
                }, 0);
                return total.toFixed(2);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return (
          Math.round(Number(val)) +
          "% " +
          "(" +
          opts.w.globals.series[opts.seriesIndex].toFixed() +
          ")"
        );
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        gradientToColors: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
        stops: [20, 100],
      },
    },
    legend: {
      position: "bottom",
      labels: {
        colors: "#F0F0F0",
        useSeriesColors: false,
      },
    },
    title: {
      align: "center",
      text: title,
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#F0F0F0",
      },
    },
    xaxis: {
      categories: uCategory,
    },

    colors: ["#FF316C", "#0086FF", "#00FF42", "#FFF200"],
    stroke: { show: false },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },

      x: { show: true },
    },
  };

  useEffect(() => {
    let _c = [];
    for (let c of category) {
      if (c === "브랜딩") {
        c = "BLENDING";
      } else if (c === "반출") {
        c = "EXPORTING";
      } else if (c === "반입") {
        c = "IMPORTING";
      } else if (c === "하역") {
        c = "UNLOADING";
      }
      _c.push(c.toUpperCase());
    }
    setUCategory(_c);

    setSeries(data);
  }, [data]);

  return (
    series && (
      <div
        style={{
          minHeight: "210px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Chart
          className="donut"
          options={chartOptions}
          type="donut"
          series={series}
        />
      </div>
    )
  );
};

export default DonutChart;
