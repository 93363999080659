import { Modal } from "antd";

const TableModal = ({ isTableMoal, modalTableDatas, handleCancel }: any) => {
  return (
    <Modal
      centered
      className="cycle"
      onCancel={handleCancel}
      title={modalTableDatas["title"]}
      visible={isTableMoal}
      onOk={handleCancel}
      footer={null}
    >
      <div>
        <table
          className="scriptResult"
          border={1}
          cellPadding={4}
          style={{ width: "100%", height: "100", borderCollapse: "collapse" }}
        >
          <tbody>
            <tr
              className="tr"
              style={{
                backgroundColor: "#5167C9",
                color: "#000000",
                border: "1px solid #ffffff",
              }}
            >
              <th align="center">Tag name</th>
              <th align="center">Tag Value</th>
              <th align="center">Description</th>
            </tr>
            {modalTableDatas["data"] &&
              modalTableDatas["data"].map((r: any) => (
                <tr
                  style={{
                    border: "1px solid #ffffff",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <td align="center">{r.tagName}</td>
                  <td align="center">{r.tagValue}</td>
                  <td align="center">{r.desc}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default TableModal;
