import React, { useEffect, useState } from "react";
import styles from "@styles/carbon/allProcess.module.css";
import CsuSvg from "./svg/CsuSvg";
import GtsuSvg from "./svg/GtsuSvg";
import StackerSvg from "./svg/StackerSvg";
import Mbc from "./svg/Mbc";
import ReclaimerSvg from "./svg/ReclaimerSvg";
import MCsuSvg from "./svg/MCsuSvg";
import MGtsuSvg from "./svg/MGtsuSvg";

const EqpCard = ({ category, type, eqp, setIsEqpChart, setEqpName }: any) => {
  const [statusColor, setStatusColor] = useState("#BF1304");
  const [titleColor, setTitleColor] = useState("");

  const setColor = (data: any) => {
    if (data === undefined) return;
    if (data.status === "stop") {
      setStatusColor("#BF1304");
      setTitleColor("red");
    } else if (data.status === "run") {
      setStatusColor("#15B200");
      setTitleColor("green");
    } else {
      setStatusColor("#ffa500");
      setTitleColor("white");
    }
  };

  useEffect(() => {
    setColor(eqp);
  }, [eqp]);

  const setSvg = (type: String, eqp: any) => {
    switch (type) {
      case "CSU":
        return (
          <CsuSvg
            statusColor={statusColor}
            titleColor={titleColor}
            data={eqp}
            setIsEqpChart={setIsEqpChart}
            setEqpName={setEqpName}
          />
        );
      case "GTSU":
        return (
          <GtsuSvg
            statusColor={statusColor}
            titleColor={titleColor}
            data={eqp}
            setIsEqpChart={setIsEqpChart}
            setEqpName={setEqpName}
          />
        );
      case "ST":
        return (
          <StackerSvg
            statusColor={statusColor}
            titleColor={titleColor}
            data={eqp}
            setIsEqpChart={setIsEqpChart}
            setEqpName={setEqpName}
          />
        );
      case "BC":
        return (
          <Mbc
            statusColor={statusColor}
            titleColor={titleColor}
            data={eqp}
            setIsEqpChart={setIsEqpChart}
            setEqpName={setEqpName}
          />
        );
      case "RE":
        return (
          <ReclaimerSvg
            statusColor={statusColor}
            titleColor={titleColor}
            data={eqp}
            setIsEqpChart={setIsEqpChart}
            setEqpName={setEqpName}
          />
        );
    }
  };
  const setUnloadSvg = (type: String, eqp: any) => {
    switch (type) {
      case "CSU":
        return (
          <MCsuSvg
            statusColor={statusColor}
            titleColor={titleColor}
            data={eqp}
            setIsEqpChart={setIsEqpChart}
            setEqpName={setEqpName}
          />
        );
      case "GTSU":
        return (
          <MGtsuSvg
            statusColor={statusColor}
            titleColor={titleColor}
            data={eqp}
            setIsEqpChart={setIsEqpChart}
            setEqpName={setEqpName}
          />
        );
    }
  };

  const showModal = () => {
    setIsEqpChart(true);
    setEqpName(eqp.eqpName);
  };

  return (
    <div
      className={styles.eqpCard}
      onClick={() => {
        showModal();
      }}
    >
      {eqp !== undefined &&
        (category === "unload" ? (
          <div
            className={`${styles.unload} ${styles.eqpCardhover}`}
            style={{ border: `1px solid ${statusColor}` }}
          >
            <div className={styles.svgContainer}>
              <div className={styles.svg}>{setUnloadSvg(eqp.facNm, eqp)}</div>
              <div className={styles.unloadTitle}>{eqp.eqpName}</div>
            </div>
            <div className={styles.values}>
              <div className={styles.unloadCardValue}>
                C0<span style={{ fontSize: "10px" }}>2</span>:
                {eqp.eAmont1.toFixed(6)}
              </div>
              <div className={styles.unloadCardValue}>
                CH<span style={{ fontSize: "10px" }}>4</span>:
                {eqp.eAmont2.toFixed(6)}
              </div>
              <div className={styles.unloadCardValue}>
                N<span style={{ fontSize: "10px" }}>2</span>0:
                {eqp.eAmont3.toFixed(6)}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={styles.eqpCardhover}
            style={{ border: `1px solid ${statusColor}` }}
          >
            <div className={styles.svgContainer}>
              <div className={styles.svg}>{setSvg(eqp.facNm, eqp)}</div>
              <div className={styles.eqpCardtitle}>{eqp.eqpName}</div>
            </div>
            <div className={styles.values}>
              <div className={styles.eqpCardValue}>
                C0<span>2</span>:{eqp.eAmont1.toFixed(2)}
              </div>
              <div className={styles.eqpCardValue}>
                CH<span>4</span>:{eqp.eAmont2.toFixed(2)}
              </div>
              <div className={styles.eqpCardValue}>
                N<span>2</span>0:
                {eqp.eAmont3.toFixed(2)}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default EqpCard;
