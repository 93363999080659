export const TYPE = {
  EQP: "eqp",
  MODULE: "module",
} as const;
// type TYPE = (typeof TYPE)[keyof typeof TYPE];

export const STATUS_CODE = {
  DOWN: "down",
  IDLE: "idle",
} as const;
// type STATUS_CODE = (typeof STATUS_CODE)[keyof typeof STATUS_CODE];

//Type: eqp,module  //status_code 'run'/'idle'/'down'
//http://192.168.0.32:38001/mod/service/api/module/idle?name=P-201A

export enum EmissionType {
  INDIRECT = "Indirect emissions source", //간접 배출원(Scope 2)
  // DIRECT = "direct emissions source", //직접 배출원(Scope 1)

  // DIRECT = "direct", //직접 배출원(Scope 1)
  // INDIRECT = "Indirect", //간접 배출원(Scope 2)
}

export enum EmissionClassification {
  ELECTRICITY = "electricity",
  // STATIONARY = "stationary combustion",
  // MOBILE = "mobile combustion",
  // STEAM = "steam(GT)",
}

export enum EmissionActivity {
  // SOLID_FUEL = "solid fuel",
  // MOBILE_AIRLINE = "mobile(airline)",
  // INDIRECT_ELECTRICITY = "Indirect(external electricity)",
  // EXTERNAL_HEAT_A = "external heat(A Type)",
  INDIRECT_ELECTRICITY = "Indirect emissions(external electricity)",
  // SOLID_FUEL = "solid fuel combustion",
  // MOBILE_AIRLINE = "mobile combustion(airline)",
  // EXTERNAL_HEAT_A = "external heat(A Type)",
}

export enum ActivityData {
  ELECTRICITY = "electricity",
  // NATURAL_GAS = "natural gas(liqeur)",
  // GASOLINE = "gasoline",
  // HEAT_STEAM = "heat/steam",
}

export interface SearchPayloadNoDate {
  emission: string;
  emissionClassification: string;
  emissionActivity: string;
  activityData: string;
}

export interface SearchPayload {
  emission: string;
  emissionClassification: string;
  emissionActivity: string;
  activityData: string;
  start: string;
  end: string;
}
