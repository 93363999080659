import axios, { AxiosResponse } from "axios";
import { setWithExpiry, getWithExpiry } from "../utils/storage";

const apiUrl = process.env.REACT_APP_AUTH_API_URL;

interface LoginInfo {
  username: string;
  password: string;
}

export const AuthService = {
  // ORIGIN LOGIN
  async login(loginInfo: LoginInfo): Promise<AxiosResponse<any>> {
    const response = await axios.post(`${apiUrl}/auth/login`, loginInfo);

    const { refreshToken } = response.data;
    await setWithExpiry("refreshToken", refreshToken);

    return response;
  },

  async logout(): Promise<AxiosResponse<any>> {
    const refreshToken = getWithExpiry("refreshToken");
    const headers = { "REFRESH-TOKEN": refreshToken };
    const response = await axios.post(`${apiUrl}/auth/logout`, {}, { headers });
    return response;
  },

  async refresh(): Promise<AxiosResponse<any>> {
    const refreshToken = await getWithExpiry("refreshToken"); // 만기 됐는지 확인
    const headers = { "REFRESH-TOKEN": refreshToken };
    const response = await axios.post(
      `${apiUrl}/auth/refresh`,
      {},
      { headers }
    );
    const { refreshToken: newRefreshToken } = response.data;
    await setWithExpiry("refreshToken", newRefreshToken);
    return response;
  },

  async currentUserValue() {
    const user = localStorage.getItem("currentUser");
    if (user) {
      const currentUser = JSON.parse(user);
      return currentUser;
    }
    return null;
  },

  // getWebSocketHeader(): { Authorization: string } {
  //   const currentUser: any = localStorage.getItem("currentUser");
  //   return { Authorization: `Bearer ${currentUser.access_token}` };
  // },
};
