import React, {useEffect} from "react";
import {Breadcrumb, BreadcrumbProps, Button, Dropdown, Layout, MenuProps, theme} from "antd";
import {GlobalOutlined, LeftOutlined, UserOutlined,} from "@ant-design/icons";
import {useRecoilState, useRecoilValue} from "recoil";
import {
  authSelector,
  collapsedState,
  headerState,
  rcpCompanyState,
  rcpSystemState,
  trnCompanyState,
  trnSystemState,
} from "../store/atom";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {AuthService} from "../services/AuthService";
import dataShareService from "../services/dataShareService";
import {GrMenu} from "react-icons/gr";
import {FaArrowLeftLong, FaCircleUser} from "react-icons/fa6";
import {AiOutlineRollback} from "react-icons/ai";
import {FaArrowLeft} from "react-icons/fa";
import {BreadcrumbItemType} from "antd/es/breadcrumb/Breadcrumb";

const { Header } = Layout;
const { useToken } = theme;

const HeaderBar = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useRecoilState(authSelector);
  // const [companyList, setCompanyList] = useRecoilState(companyListState);
  const [collapsed, setCollapsed] = useRecoilState<boolean>(collapsedState);
  const { title, subTitle, subSubTitle, isGoBackVisible } = useRecoilValue<any>(headerState);
  const onClickCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const [, setTrnCompany] = useRecoilState<any>(trnCompanyState);
  const [, setTrnSystem] = useRecoilState<any>(trnSystemState);
  const [, setRcpCompany] = useRecoilState<any>(rcpCompanyState);
  const [, setRcpSystem] = useRecoilState<any>(rcpSystemState);

  // TOBE :  1. 송신 기업 조회
  // 2. 송신 시스템 : 송신 기업 응답의 첫번째 데이터로 송신 시스템 조회
  const getTrnSystem = async () => {
    const userId = "1";
    await dataShareService.getCompanySystemList(userId).then((res) => {
      const result = res.data.list;
      let list: any = [];
      for (let r of result) {
        const obj = { value: r.sysId, label: r.sysNm };
        list.push(obj);
      }
      setTrnSystem(list);
    });
  };

  // 3. 수신 기업 조회
  const getRcpCompany = async () => {
    let list: any = [];
    await dataShareService.getCompanyList().then((res) => {
      const result = res.data.list;
      for (let r of result) {
        const obj = { value: r.userId, label: r.userNm };
        list.push(obj);
      }
      setRcpCompany(list);
    });

    // 4. 수신 시스템 : 수신 기업 응답의 첫번째 데이터로 송신 시스템 조회
    const userId = list[0]["value"];
    await dataShareService.getCompanySystemList(userId).then((res) => {
      const result = res.data.list;
      let list: any = [];
      for (let r of result) {
        const obj = { value: r.sysId, label: r.sysNm };
        list.push(obj);
      }
      setRcpSystem(list);
    });
  };

  useEffect(() => {
    getRcpCompany();
    getTrnSystem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 언어 설정
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("locale");
    i18n.changeLanguage(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleLocales = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("locale", lang);
    window.location.reload();
  };

  const onClickLogout = () => {
    AuthService.logout()
      .then(() => {

      })
      .catch((error) => {
        console.error("logout error", error);
      })
      .finally(() => {
        setAuth(null);
        localStorage.clear();
        navigate("/login");
      });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Logout",
      icon: <UserOutlined />,
      onClick: () => onClickLogout(),
    },
    {
      key: "2",
      label: "Language",
      icon: <GlobalOutlined />,
      children: [
        {
          key: "2-1",
          label: "한글",
          onClick: () => toggleLocales("ko-KR"),
        },
        {
          key: "2-2",
          label: "English",
          onClick: () => toggleLocales("en-US"),
        },
      ],
    },
  ];

  const { token } = useToken();
  const iconColor = token.colorPrimary;
  const goBack = () => {
    window.history.back();
  }
  const brItems: BreadcrumbItemType[] = [
    { title: title },
    { title: <span className={'breadcrumb-last'}>{subTitle}</span> },
    subSubTitle ? { title: <span className={'breadcrumb-last'}>{subSubTitle}</span> } : null
  ].filter(Boolean);

  return (
    <>
      <Header id="header">
        <div className='header-left'>
          <div className={'menu-toggle'}>
            <Button type='text' shape='circle'
                    icon={<GrMenu size={24} color='#05507D'
                    onClick={onClickCollapsed}
            />}/>
          </div>
          {isGoBackVisible && (
            <Button icon={<FaArrowLeftLong size={20} color={'#05507D'}/>} type='text' shape='round' onClick={goBack}>
              <div style={{color: '#05507D', fontSize: '16px', fontWeight: 'bold'}}>
                {subTitle}
              </div>
            </Button>
          )}
        </div>
        <div className="header-right">
            <Dropdown menu={{ items }}>
              <FaCircleUser size={32} color={iconColor} />
            </Dropdown>
        </div>
      </Header>
      <div className="header-bottom">
        <Breadcrumb
          className="breadcrumb"
          separator=">"
          items={brItems}
        >
          {/*<Breadcrumb.Item>{title}</Breadcrumb.Item>*/}
          {/*<Breadcrumb.Item className={'breadcrumb-last'}>{subTitle}</Breadcrumb.Item>*/}
          {/*{subSubTitle && <Breadcrumb.Item className={'breadcrumb-last'}>{subSubTitle}</Breadcrumb.Item>}*/}
        </Breadcrumb>
      </div>
    </>
  );
};

export default HeaderBar;
