import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import {RouterProvider} from "react-router-dom";
import {RecoilRoot} from "recoil";
import "./locales/i18n";
import {ConfigProvider} from "antd";
import router from "./routes/routers";
import {DebugObserver} from "@utils/DebugObserver";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const colorPrimary = '#b9a394'
const siderBg = '#05507D'

const theme = {
  token: {
    colorPrimary,
    borderRadius: 3,
  },
  components: {
    Table: {
      headerColor: '#ffffff',
      headerBg: '#4B5151',
      headerSortHoverBg: 'rgba(75,81,81,0.85)',
      headerBorderRadius: 4,
    },
    Layout: {
      headerBg: '#f7f7f7',
      siderBg,
    },
    Menu: {
      darkItemBg: siderBg,
      darkSubMenuItemBg: '#054871',
      darkItemSelectedBg: '#377397',
      itemBorderRadius: 0,
    },
  },
};

root.render(
  <RecoilRoot>
    { process.env.NODE_ENV === 'development' &&  <DebugObserver />}
    <ConfigProvider theme={theme}>
      <RouterProvider router={router}/>
    </ConfigProvider>
  </RecoilRoot>
);