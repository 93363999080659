import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { headerState } from "../../../store/atom";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import LineBasicChart from "./LineBasicChart";
import styles from "@styles/carbon/reporting.module.css";
import service from "@services/carbontaxService";
import data from "../../../data/index.json";

const allColumns = data["carbonTax"]["reporting"]["allColumns"];
const columns = data["carbonTax"]["reporting"]["columns"];
const traceInfoCol = data["carbonTax"]["reporting"]["traceInfoCol"];
const series = data["carbonTax"]["reporting"]["chart"]["data"];
const category = data["carbonTax"]["reporting"]["chart"]["category"];
const Report = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:carbonEmissions"),
      subTitle: t("main:header:report"),
      path: "/carbonTax/report",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [allDataSource, setAllDataSource] = useState<any>();
  const [dataSource, setDataSource] = useState<any>();
  const [infoData, setInfoData] = useState<any>({});
  const [traceInfo, setTraceInto] = useState<any>();

  const getProductInfo = () => {
    service.getProductInfo("LOT001").then((res) => {
      setInfoData(res.data.result[0]);
    });
  };

  const getAllProcessGross = () => {
    service.getAllProcessGross("LOT001").then((res) => {
      const data = res.data.result;
      let _r = [];
      for (let i in data) {
        const _data = { ...data[i], key: i };
        _r.push(_data);
      }
      setAllDataSource(_r);
    });
  };

  const getProcessGross = () => {
    service.getProcessGross("LOT001").then((res) => {
      const data = res.data.result;
      let _r = [];

      for (let i in data) {
        const _data = { ...data[i], key: i };
        _r.push(_data);
      }
      setDataSource(_r);
    });
  };

  const getTraceabilityInfo = () => {
    service.getTraceabilityInfo("LOT001").then((res) => {
      const data = res.data.result;
      let _r = [];

      for (let i in data) {
        const _data = { ...data[i], key: i };
        _r.push(_data);
      }
      setTraceInto(_r);
    });
  };

  useEffect(() => {
    getProductInfo();
    getAllProcessGross();
    getProcessGross();
    getTraceabilityInfo();
  }, []);

  return (
    <div className={styles.templete}>
      <div className={styles.info}>
        <div className={styles.title}>
          <div className={styles.innerTitle}>
            <div className={styles.innerText}>
              Product CO
              <div>2</div>
              eq Summary
            </div>
          </div>
        </div>
        <div className={styles.productContainer}>
          {infoData && (
            <div>
              <ul>
                <li>
                  <div className={styles.title}>Product Name</div>:{" "}
                  {infoData["productNm"]}
                </li>
                <li>
                  <div className={styles.title}>Product No</div>:{" "}
                  {infoData["productNo"]}
                </li>
                <li>
                  <div className={styles.title}>
                    Total CO
                    <span>2</span>
                    eq
                  </div>
                  : {infoData["total"]}
                  &nbsp;CO
                  <div>
                    <span>2</span>
                  </div>
                  eq
                </li>
              </ul>
            </div>
          )}

          <div style={{ display: "flex" }}>
            <div className={styles.imgBox}>
              <img src="/img/tuv1.png" alt="logo" width={145} />
            </div>
            <div className={styles.imgBox}>
              <img src="/img/poscologo.png" alt="logo" width={180} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>All Product Gross</div>
        <Table
          dataSource={allDataSource}
          columns={allColumns}
          pagination={false}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>Product Gross</div>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>Traceability Chart</div>
        <LineBasicChart height={320} data={series} category={category} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>Trace Info</div>
        <Table
          dataSource={traceInfo}
          columns={traceInfoCol}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Report;
