import axios, {AxiosResponse} from "axios";
import {ShareHistoryTransRes, ShareHistoryTransDetailRes} from "@pages/shareData/history/history";

const apiUrl = process.env.REACT_APP_DX_API_URL;
const dataShareService = {
  /**
   * @decs 데이터 송수신 기업/시스템
   * @returns
   */
  async getCompanyList() {
    const response = await axios.get(`${apiUrl}/data/status/company`);
    return response;
  },

  async getCompanySystemList(id: string) {
    const response = await axios.post(`${apiUrl}/data/status/company/${id}`);
    return response;
    // const response = {
    //   data: {
    //     list: [
    //       {
    //         sysNm: "원료PC",
    //         sysId: "dafa9343-4ab4-47d0-ad90-713f541034110c",
    //       },
    //       {
    //         sysNm: "원료PC11",
    //         sysId: "dafa9343-4ab4-47d0-ad90-713f5410340c",
    //       },
    //     ],
    //   },
    // };
    // return response;
  },

  /**
   * @decs 데이터 유입/유출에 대한 양,건수 조회
   * @returns
   */
  async getSummaryData() {
    const response = await axios.get(`${apiUrl}/data/dashboard/summary-data`);
    return response;
  },

  /**
   * @desc 데이터 수집 현황 목록 조회
   * @param payload
   * @returns
   */
  async getDataStatusList(payload: any) {
    const response = await axios.post(`${apiUrl}/data/status/search`, payload);
    return response;
  },

  /**
   * @desc 데이터 수집 이력 목록
   * @param payload
   * @returns
   */
  async getDataHistoryList(payload: any) {
    const response = await axios.post(`${apiUrl}/data/history/search`, payload);
    // const response = data["result"]["dataHistoryList"];
    return response;
  },

  /**
   * @desc 데이터 수집 이력 트랜잭션 목록
   * @param payload
   * @returns
   */
  async getDataTransactionList(payload: any):Promise<AxiosResponse<ShareHistoryTransRes>> {
    const response = await axios.post(
      `${apiUrl}/data/history/search/${payload.dataSharId}`,
      payload.obj
    );
    // const response = data["result"]["dataTransactionList"];
    return response;
  },

  /**
   * @desc 데이터 수집 이력 트랜잭션 상세 팝업
   * @param payload
   * @returns
   */
  async getTransactionDetailInfo(payload: any):Promise<AxiosResponse<ShareHistoryTransDetailRes>> {
    const response = await axios.get(
      `${apiUrl}/data/history/transaction/${payload.transactionId}/${payload.datasetId}`
    );
    return response;
  },

  /**
   * @desc 데이터셋 목록
   * @returns
   */
  async getDatasetList() {
    const response = await axios.get(`${apiUrl}/analysis/dataset`);
    return response;
  },

  /**
   * @desc 데이터셋 세부 항목
   * @param payload
   * @returns
   */
  async getDatasetDetail(payload: any) {
    const response = await axios.get(`${apiUrl}/analysis/dataset/${payload}`);
    return response;
  },

  /**
   * @desc 데이터 송/수신 건수 조회
   * @returns
   */
  async getDataSharingObjCount(payload: any) {
    const response = await axios.post(
      `${apiUrl}/data/dashboard/data-sharing-count`,
      payload
    );
    return response;
  },

  /**
   * @desc 데이터 공유 사이즈 추이 조회
   * @returns
   */
  async getDataSize(payload: any) {
    const response = await axios.post(
      `${apiUrl}/data/dashboard/data-size`,
      payload
    );
    return response;
  },

  /**
   * @desc 데이터 공유 건수 추이 조회
   * @returns
   */
  async getDataCount(payload: any) {
    const response = await axios.post(
      `${apiUrl}/data/dashboard/data-count`,
      payload
    );
    return response;
  },

  /**
   * @desc 데이터 셋별 공유 추이(일간)
   * @returns
   */
  async getDataCountDaliy() {
    const response = await axios.get(
      `${apiUrl}/data/dashboard/data-count-daily`
    );
    return response;
  },

  /**
   * @desc 데이터 셋별 공유 추이(주간)
   * @returns
   */
  async getDataCounWeek() {
    const response = await axios.get(
      `${apiUrl}/data/dashboard/data-count-week`
    );
    return response;
  },

  /**
   * @desc 시간별 데이터 공유 건수
   * @returns
   */
  async getDataSharingHour(payload: any) {
    const response = await axios.post(
      `${apiUrl}/data/dashboard/data-sharing-hour`,
      payload
    );

    return response;
  },

  /**
   * @desc 데이터 누적 처리 현황
   * @returns
   */
  async getDataAccStatus(payload: any) {
    const response = await axios.post(
      `${apiUrl}/data/dashboard/data-status`,
      payload
    );
    return response;
  },

  async getDataCountDaliyList(payload: any) {
    const response = await axios.post(
      `${apiUrl}/data/dashboard/data-count-daily-list`,
      payload
    );
    return response;
  },

  async getDataCounWeekList(payload: any) {
    const response = await axios.post(
      `${apiUrl}/data/dashboard/data-count-week-list`,
      payload
    );
    return response;
  },

  async getUserInfo(page: number, size: number) {
    const response = await axios.get(
      `${apiUrl}/data/user-info?page=${page}&size=${size}`
    );
    return response;
  },
};

export default dataShareService;
