import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const DonutChart = ({ data, width, label, height }: any) => {
  const [series, setSeries] = React.useState<ApexAxisChartSeries>([]);

  const chartOptions: ApexOptions = {
    noData: { text: "no data", align: "center", verticalAlign: "middle" },
    labels: label,
    plotOptions: {
      pie: {
        offsetX: 0,
        offsetY: 0,
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              color: "#000",
              offsetY: 25,
            },
            value: {
              show: true,
              fontSize: "20px",
              fontFamily: "Noto Sans KR",
              // fontWeight: 400,
              offsetY: -20,
              color: "#000",
            },
            total: {
              show: true,
              fontSize: "14x",
              fontFamily: "Noto Sans KR",
              // fontWeight: 400,
              color: "#000",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return (
          opts.w.globals.series[opts.seriesIndex].toFixed() +
          "(" +
          Math.round(Number(val)) +
          "%)"
        );
      },
    },

    legend: {
      position: "bottom",
      labels: {
        colors: "#000",
      },
      fontSize: "14px",
      fontFamily: "Noto Sans KR",
    },

    stroke: { show: false },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },

      // x: { show: true },
    },
  };

  useEffect(() => {
    setSeries(data);
  }, [data]);

  return (
    series && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Chart
          className="donut"
          options={chartOptions}
          type="donut"
          series={series}
          width={width}
        />
      </div>
    )
  );
};

export default DonutChart;
