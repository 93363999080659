import React, {useState} from "react";
import {Button, Card, ConfigProvider, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import styles from "../../styles/common/login.module.css";
import {useNavigate} from "react-router-dom";
import {AuthService} from "@services/AuthService";
import {tokenDecoder} from "@utils/tokenDecoder";
import {useRecoilState} from "recoil";
import {authSelector} from "@store/atom";

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [auth, setAuth] = useRecoilState(authSelector);

  const onClickLogin = (username: string, password: string) => {
    AuthService.login({ username, password })
      .then((res) => {
        setErrorMessage("");
        setLoading(true);
        const result = tokenDecoder(res?.data);
        setAuth(result);

        setTimeout(() => {
          navigate("../shareData/status");
          setLoading(false); // 네비게이션 후 로딩 상태를 false로 설정
        }, 400);
      })

      .catch((e) => {
        setErrorMessage("ID 또는 비밀번호가 올바르지 않습니다.");
      })
      .finally(() => {
        form.resetFields();
      });
  };

  const onSubmitForm = (values: any) => {
    onClickLogin(values.username, values.password);
  };

  return (
    <div className={styles.loginPage}>
      <Card className={styles.loginForm}>
        <div className={styles.loginHeader}>
          <h1>POSCO DX</h1>
          <div className={styles.loginDesc}>연속공정특성산업의 기업연계 개방형 스마트 제조 공유 플랫폼 </div>
        </div>
        <div>
          <Form form={form} onFinish={onSubmitForm} wrapperCol={{ span: 20, offset: 2 }} variant={"filled"}>
            <Form.Item
              name="username"
              style={{ marginBottom: 12 }}
            >
              <Input
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="ID"
                style={{ height: "36px" }}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: "필수 항목입니다." }]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Password"
                style={{ height: "36px" }}
              />
            </Form.Item>
            <Form.Item>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#032341",
                    controlHeight: 40,
                  },
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{ width: "100%", marginTop: "10px" }}
                >
                Login
                </Button>
              </ConfigProvider>
              </Form.Item>
          </Form>
          <div style={{ color: "#DB3B21", textAlign: "center" }}>
            {errorMessage}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Login;
