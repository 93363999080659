import React from "react";
import styles from "@styles/carbon/traceChartModal.module.css";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import LineBasicChart from "./TraceBarChart";

const TraceChartModal = ({ isModalOpen, handleOk }: any) => {
  return (
    <Modal
      className="charModal"
      closable={false}
      open={isModalOpen}
      width={1500}
      bodyStyle={{ background: "#1B1E46", borderRadius: "10px" }}
      footer={null}
    >
      <div className={styles.template} style={{ height: "900px" }}>
        <div className={styles.title}>
          <div>[S000001] Trace Data</div>
          <div
            className={styles.hover}
            onClick={() => {
              handleOk();
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        <div className={styles.chart}>
          <div>
            <LineBasicChart />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TraceChartModal;
