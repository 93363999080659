import React from "react";

const NumberCard = ({ title, value, subValue }: any) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "10px",
      }}
    >
      <div
        style={{
          flex: 1,
          fontSize: "18px",
          textDecoration: "underline",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {title}
      </div>
      <div
        style={{
          flex: 3,
          fontSize: "30px",
          display: "flex",
          alignItems: "end",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        {value}
        {subValue && (
          <span style={{ fontSize: "14px", paddingBottom: "6px" }}>
            ({subValue}%)
          </span>
        )}
      </div>
    </div>
  );
};

export default NumberCard;
