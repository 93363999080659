import axios, { AxiosResponse } from "axios";

export interface ErrorLogSearchCondition {
  startDateTime: string; // "20240101000000"
  endDateTime: string; // "20241201000000"
  page: number; // 1
  size: number; // 10
}

export interface ErrorLogResponsePage {
  list: ErrorLog[] | [];
  totalCount: number;
  totalPages: number;
  pageSize: number;
}

export interface ErrorLog {
  trnsUserNm: string;
  trnsSysNm: string;
  rcptnUserNm: string;
  rcptnSysNm: string;
  dataSharId: string; // uuid
  datasetName: string;
  datasetId: string; // uuid
  trnsMthd: string;
  rcptnMthd: string;
  trnsDt: number | null;
  rcptnDt: number | null;
  transactionId: string; // uuid
  fullText: string;
  errorMessage: string;
}

const apiUrl = process.env.REACT_APP_DX_API_URL;
const errorLogService = {
  /**
   * @desc 에러 로그 조회하는 메서드
   * @returns
   */
  async getErrorLogList(
    payload: ErrorLogSearchCondition
  ): Promise<AxiosResponse<ErrorLogResponsePage>> {
    const response = await axios.post(`${apiUrl}/error/log/search`, payload);
    return response;
  },
};

export default errorLogService;
