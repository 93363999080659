import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { headerState } from "../../../store/atom";
import { useTranslation } from "react-i18next";
import GradientDonutChart from "./GradientDonutChart";
import StackedBarChart from "./StackedBarChart";
import data from "../../../data/index.json";
import SearchComponent, {
  initSearchValues,
  SearchValues,
} from "../SearchComponent";
import service from "@services/carbontaxService";
import { LoadingOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import { Table, Tabs, Button } from "antd";
import moment from "moment";
import { Excel } from "antd-table-saveas-excel";
import styles from "@styles/carbon/emissionsCal.module.css";

const columns = data["carbonTax"]["emissionsCal"]["columns"];
const colorList: any = data["colorList"];

export interface SearchPayloadNoDate {
  emission: string;
  emissionClassification: string;
  emissionActivity: string;
  activityData: string;
}

export interface SearchPayload {
  emission: string;
  emissionClassification: string;
  emissionActivity: string;
  activityData: string;
  start: string;
  end: string;
}
interface iChartDataUnit {
  name: string;
  data: number[];
}

const { TabPane } = Tabs;

const EmissionsCal = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:carbonEmissions"),
      subTitle: t("main:header:emissionsCal"),
      path: "/carbonTax/emissionsCal",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cWidth, setCWidth] = useState<any>(null);
  const [tHeight, setTHeight] = useState<any>(null);
  const [emissionsChartData, setEmissionsChartData] = useState<any>(null);
  const [emissionsTableData, setEmissionsTableData] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<any>(null);

  const changeTab = (activeKey: any) => {
    console.log("activeKey", activeKey);
    switch (activeKey) {
      case "1":
        setActiveTab(null);
        break;

      case "2":
        setActiveTab("unloading");
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setEmissionsTable();
  }, [activeTab]);

  const onClickExport = () => {
    let dataSource: any = [];
    for (let e of emissionsTableData) {
      const data = {
        serialNum: e.serialNum,
        processNm: e.processNm,
        facNm: e.facNm,
        fuleNm: e.fuleNm,
        fuleUsage: e.fuleUsage,
        fuleUnit: e.fuleUnit,
        feverUsage: e.feverUsage,
        feaverUnit: e.feaverUnit,
        eFactor1: e.eFactor1,
        eFactor2: e.eFactor2,
        eFactor3: e.eFactor3,
        eAmont1: e.eAmont1,
        eAmont2: e.eAmont2,
        eAmont3: e.eAmont3,
        eSumAmount: e.eSumAmount,
        usageM: e.usageM,
        usageT: e.usageT,
      };
      dataSource.push({ ...data });
    }
    const fileName = "emissions_" + moment().format("YYYYMMDD") + ".xlsx";
    const excel = new Excel();
    excel
      .addSheet("sheet1")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true,
      })
      .saveAs(fileName);
  };

  const setEmissionChart = (searchValues: SearchValues) => {
    const { emission, emissionClassification, emissionActivity, activityData } =
      searchValues;

    const payload = {
      emission,
      emissionClassification,
      emissionActivity,
      activityData,
      start: `${initSearchValues.startDate}T${initSearchValues.startTime}`,
      end: `${initSearchValues.endDate}T${initSearchValues.endTime}`,
    };

    service.getEmissionCheck(payload).then((res: any) => {
      const results = res.data.result;
      let category = [];
      let chartFormat: iChartDataUnit[] = [
        { name: "CO2", data: [] },
        { name: "CH4", data: [] },
        { name: "N2O", data: [] },
      ];

      for (let result of results) {
        category.push(result.processNm);
        const dataList = result.data;
        const datakeys = ["eAmount1", "eAmount2", "eAmount3"];
        for (let index = 0; index < 3; index++) {
          // const dataKey = "eAmont" + (index + 1);
          const dataKey = datakeys[index];
          const value: any = Number(dataList[dataKey].toFixed(2));
          chartFormat[index]["data"].push(value);
        }
      }

      let data: any = [];
      for (let t of Object.entries(chartFormat)) {
        data.push(t[1]);
      }

      const _eChartData = {
        title: "Emission Source Data Chart by Process",
        category,
        data,
      };

      setEmissionsChartData(_eChartData);
    });
  };

  //const setEmissionsTable = (selectValue: any, date: string) => {
  // TODO : selectValue 받아오기
  const setEmissionsTable = () => {
    setEmissionsTableData(null);
    const { emission, emissionClassification, emissionActivity, activityData } =
      initSearchValues;

    const payload = {
      emission,
      emissionClassification,
      emissionActivity,
      activityData,
      processNm: activeTab,
      start: `${initSearchValues.startDate}T${initSearchValues.startTime}`,
      end: `${initSearchValues.endDate}T${initSearchValues.endTime}`,
    };

    // 테이블 데이터 조회
    service.getEmissionDesc(payload).then((res: any) => {
      const results = res.data.result;
      setEmissionsTableData(results);
    });
  };

  useEffect(() => {
    setEmissionsTable();
    setEmissionChart(initSearchValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tHeight = window.innerHeight - 750;
    const cWidth = window.innerWidth - 800;
    setCWidth(cWidth);
    setTHeight(tHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSearch = (searchValues: SearchValues) => {
    setEmissionChart(searchValues);
  };

  return (
    <div className={styles.templete}>
      <SearchComponent onSearch={onClickSearch} />
      <div>
        <div className={styles.chartSection}>
          {!emissionsChartData ? (
            <div className={styles.loading}>
              <LoadingOutlined style={{ fontSize: 48, color: "gray" }} spin />
            </div>
          ) : (
            <>
              <div className={styles.leftChart}>
                <StackedBarChart
                  title={emissionsChartData.title}
                  data={emissionsChartData.data}
                  category={emissionsChartData.category}
                  width={cWidth}
                  height={330}
                />
              </div>

              <div className={styles.rightChart}>
                <div className={styles.title}>Process Total Emissions</div>
                <div className={styles.chart1}>
                  <GradientDonutChart
                    title={"Accumulated Power by Process"}
                    data={emissionsChartData.data[0].data}
                    category={emissionsChartData.category}
                    colorList={colorList.blue}
                  />
                </div>
                <div>
                  <div className={styles.chart2}>
                    <GradientDonutChart
                      title={"Accumulated Power by Process"}
                      data={emissionsChartData.data[1].data}
                      category={emissionsChartData.category}
                      colorList={colorList.yellow}
                    />
                  </div>
                  <div className={styles.chart3}>
                    <GradientDonutChart
                      title={"Accumulated Power by Process"}
                      data={emissionsChartData.data[2].data}
                      category={emissionsChartData.category}
                      colorList={colorList.darkBlue}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          style={{ marginTop: "10px", width: "110px" }}
          type="primary"
              className="searchbtn"
          onClick={() => {
            onClickExport();
          }}
        >
          <DownloadOutlined />
          Export
        </Button>
      </div>
      <div className={styles.tableTab}>
        <Tabs
          type="card"
          className="tableTab"
          defaultActiveKey="1"
          size="middle"
          onChange={(activekey) => changeTab(activekey)}
        >
          <TabPane tab="All" key="1">
            <div className={styles.tableSection}>
              <Table
                dataSource={emissionsTableData}
                columns={columns}
                pagination={false}
                scroll={{ y: tHeight }}
              ></Table>
            </div>
          </TabPane>
          <TabPane tab="Unload" key="2">
            <Table
              dataSource={emissionsTableData}
              columns={columns}
              pagination={false}
              scroll={{ y: tHeight }}
            ></Table>
          </TabPane>
          <TabPane tab="Import" key="3"></TabPane>
          <TabPane tab="Export" key="4"></TabPane>
          <TabPane tab="Blending" key="5"></TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default EmissionsCal;
