import { useState } from "react";
import { Modal, Button, message } from "antd"; // Ant Design 사용 예시
import { CopyOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import styles from "@/styles/dataShare/errorLogHistory.module.css";

const maxLength = 150;

const ErrorLogFullTextModal = ({
  errorMessage,
  trnsDt,
  text,
}: {
  errorMessage: string;
  trnsDt: string;
  text: string;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isTextLengthValid =
    parseInt(text.substring(86, 91), 10) === text.length - 86;

  const getShortenedText = (str: string) => {
    return str.length > maxLength ? `${str.substring(0, maxLength)}…` : str;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const copyToClipboard = (content: string) => {
    try {
      navigator.clipboard.writeText(content).then(() => {
        message.success(
          "텍스트 (length: " + content.length + ")가 클립보드에 복사되었습니다."
        );
      });
    } catch (error) {
      fallbackCopyToClipboard(content);
    }
  };

  const fallbackCopyToClipboard = (content: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = content;
    textArea.style.position = "fixed";
    textArea.style.top = "-9999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        message.success(
          "텍스트 (length: " + content.length + ")가 클립보드에 복사되었습니다."
        );
      } else {
        message.error("클립보드 복사에 실패했습니다.");
      }
    } catch (err) {
      console.error("복사 중 오류가 발생했습니다:", err);
    }

    document.body.removeChild(textArea);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <span onClick={showModal} style={{ cursor: "pointer", color: "blue" }}>
        {getShortenedText(text)}
      </span>

      <Modal
        width={"80vw"}
        height={"60vh"}
        title={
          <div>
            전문 보기 (full: {text.length} / body: {text.length - 86}){" "}
            <span style={{ fontSize: 12 }}>
              {" "}
              송신일자: {dayjs(trnsDt).format("YYYY-MM-DD HH:mm:ss")}
            </span>
            <Button
              onClick={() => copyToClipboard(text)}
              style={{
                marginLeft: "20px",
              }}
            >
              <CopyOutlined />
              전문 클립보드에 복사
            </Button>
          </div>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ overflowY: "auto", maxHeight: "70vh" }} // 스크롤 활성화
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            확인
          </Button>,
        ]}
      >
        <p style={{ padding: "20px" }}>
          에러 메세지:{" "}
          <Button onClick={() => copyToClipboard(errorMessage)}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={errorMessage}
            />
          </div>
          헤더 header:{" "}
          <Button onClick={() => copyToClipboard(text.substring(0, 86))}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={text.substring(0, 86)}
            />
          </div>
          인터페이스 ID interfaceID(20):
          <Button onClick={() => copyToClipboard(text.substring(0, 20))}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={text.substring(0, 20)}
            />
          </div>
          데이터 셋 UUID dataSetUuid(36):
          <Button onClick={() => copyToClipboard(text.substring(20, 56))}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={text.substring(20, 56)}
            />
          </div>
          유저 아이디 user ID(30):
          <Button onClick={() => copyToClipboard(text.substring(56, 86))}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={text.substring(56, 86)}
            />
          </div>
          <div
            className={styles.errorModalButtonAndTextContainer}
            style={{ flexDirection: "row" }}
          >
            길이 length(5):
            <Button onClick={() => copyToClipboard(text.substring(86, 91))}>
              <CopyOutlined /> 복사
            </Button>
            {isTextLengthValid ? (
              <CheckOutlined style={{ color: "green", marginLeft: "10px" }} />
            ) : (
              <>
                <CloseOutlined style={{ color: "red", marginLeft: "10px" }} />
                전문에 명시된 길이: {parseInt(text.substring(86, 91), 10)} !==
                실제로 받은 길이: {text.length - 86}
              </>
            )}
          </div>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              readOnly
              value={text.substring(86, 91)}
            />
          </div>
          전문 fullText:
          <span style={{ width: "20px" }}> </span>
          <Button onClick={() => copyToClipboard(text)}>
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              style={{ height: "30vh" }}
              readOnly
              value={text}
            />
          </div>
          바디 body (헤더 제외):
          <span style={{ width: "20px" }}> </span>
          <Button
            onClick={() => copyToClipboard(text.substring(86, text.length))}
          >
            <CopyOutlined /> 복사
          </Button>
          <div className={styles.errorModalButtonAndTextContainer}>
            <textarea
              className={styles.errorLogTextarea}
              style={{ height: "30vh" }}
              readOnly
              value={text.substring(86, text.length)}
            />
          </div>
        </p>
      </Modal>
    </div>
  );
};

export default ErrorLogFullTextModal;
