import React, {useEffect, useState} from "react";
import {ConfigProvider, Modal, Tooltip} from "antd";
import styles from "@styles/dataShare/dataDetailModal.module.css";
import initData from "../../../data/dataShareInit.json";
import service from "@services/dataShareService";
import {useTranslation} from "react-i18next";
import {JSONTree} from "react-json-tree";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {ShareHistoryTransData, ShareHistoryTransDetailRes} from "@pages/shareData/history/history";

interface Props {
  visible: boolean;
  handleOk: () => void;
  title: string;
  transactionId: string;
  param: ShareHistoryTransData;
}

const DataDetailModal = ({
                           visible,
                           handleOk,
                           title,
                           transactionId,
                           param,
                         }: Props) => {
  const {t} = useTranslation();

  const [resData, setResData] = useState<ShareHistoryTransDetailRes>();
  const [dataInfo, setDataInfo] = useState<any>(
    initData["dataDetailModal"]["dataInfo"]
  );

  // console.log('param', param);
  const getData = async (transactionId: string) => {
    const payload = {
      transactionId: transactionId,
      datasetId: param.datasetId,
    };

    await service.getTransactionDetailInfo(payload)
      .then((res) => {
        setResData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const setStatus = (data: string) => {
    let result = "";
    switch (data) {
      case "":
        result = "전체";
        break;
      case null:
        result = "-";
        break;
      case "Y":
        result = "완료";
        break;
      case "N":
        result = "수집중";
        break;
      default:
        result = "-";
        break;
    }
    return result;
  };

  useEffect(() => {
    getData(transactionId);
  }, []);

  const [renderedData, setRenderedData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (resData) {
      setIsLoading(true);
      const rendered = renderData(resData);
      setRenderedData(rendered);
      setIsLoading(false);
    }
  }, [resData]);

  const renderData = (resData: ShareHistoryTransDetailRes) => {
    const {meta, data} = resData;
    return (
      (
        <div className={styles.jsonTree}>
          <JSONTree
            data={resData.data}
            theme={{
              base00: '#31344e', // background
              base09: '#7cafff', // number
              base0B: '#7af8ca', // string
              base0D: '#c8d3f5', // property
            }}
            shouldExpandNodeInitially={() => true}
            labelRenderer={([key, ...rest], nodeType, expanded) => {
              const metaValue = meta[key] || key; // 메타데이터 값이 없으면 키 자체를 사용
              return (
                <span>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorBgSpotlight: '#ffffff',
                          colorTextLightSolid: '#000000',
                        },
                      }}
                    >
                      <Tooltip title={metaValue}>
                        {key}
                      </Tooltip>
                    </ConfigProvider>
                  </span>
              );
            }}
          />
        </div>
      )
    );
  }

  return (
    <Modal
      title={"[ " + title + " ] " + t("main:shareData:dataDetailPopup")}
      open={visible}
      onOk={handleOk}
      onCancel={handleOk}
      footer={false}
      width={'50vw'}
      styles={{
        body: { overflowY: 'auto' }
      }}
    >
      <div>
        {/*<div className={styles.title}>*/}
        {/*  {t("main:shareData:dataInformation")}*/}
        {/*</div>*/}
        {/*<div className={styles.tableContainer}>*/}
        {/*  <table className={styles.table} key="tableLeft">*/}
        {/*    <tbody>*/}
        {/*      <tr>*/}
        {/*        <th>{t("main:shareData:dataType")}</th>*/}
        {/*        <td>FIXED STREAM</td>*/}
        {/*      </tr>*/}
        {/*      <tr>*/}
        {/*        <th>{t("main:shareData:Status")}</th>*/}
        {/*        <td>{setStatus(param.status)}</td>*/}
        {/*      </tr>*/}
        {/*      <tr>*/}
        {/*        <th>{t("main:shareData:size")}</th>*/}
        {/*        <td>{dataInfo.dataSize} KB</td>*/}
        {/*      </tr>*/}
        {/*    </tbody>*/}
        {/*  </table>*/}
        {/*  <table className={styles.table} key="tableRight">*/}
        {/*    <tbody>*/}
        {/*    <tr>*/}
        {/*      <th>데이터 타입</th>*/}
        {/*      <td>N/A</td>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <th>쿼리 단위</th>*/}
        {/*      <td>N/A</td>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <th>세그먼트 단위</th>*/}
        {/*      <td>N/A</td>*/}
        {/*    </tr>*/}
        {/*    </tbody>*/}
        {/*  </table>*/}
        {/*</div>*/}
        {/*<div className={styles.title}>*/}
        {/*  {t("main:shareData:dataDetailItems")}*/}
        {/*</div>*/}
        {isLoading ? (
          <LoadingSpinner/>
        ) : (
          renderedData
        )}
      </div>
    </Modal>
  );
};

export default DataDetailModal;
