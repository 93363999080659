import React from "react";
import styles from "@styles/carbon/allProcess.module.css";
import EqpCard from "./EqpCard";

const EqpList = ({ category, type, eqps, setIsEqpChart, setEqpName }: any) => {
  return (
    <>
      <div className={styles.eqpList}>
        {eqps.map((eqp: any, key: any) => (
          <EqpCard
            setIsEqpChart={setIsEqpChart}
            setEqpName={setEqpName}
            category={category}
            type={type}
            eqp={eqp}
            key={eqp ? eqp.eqpName + key : "eqp" + key}
          />
        ))}
      </div>
    </>
  );
};

export default EqpList;
