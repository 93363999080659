import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const BarChart = ({ title, data, category }: any) => {
  const [series, setSeries] = React.useState<ApexAxisChartSeries>([]);
  const [colorList, setColorList] = useState<any>();

  const chartOptions: ApexOptions = {
    title: {
      align: "center",
      text: title,
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#F0F0F0",
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        formatter: function (val) {
          return val + " power";
        },
      },
      y: {
        formatter: function (val) {
          return val + "KWh";
        },
        title: {
          formatter: (seriesName) => "",
        },
      },
      marker: {
        show: true,
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },
    chart: {
      zoom: {
        enabled: false,
      },

      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: category ? category : "",
      labels: {
        style: {
          colors: ["#fff"],
          fontSize: "10px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#fff",
          fontSize: "9px",
        },
      },
      title: {
        style: {
          color: "#fff",
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      type: "solid",
    },
    colors: colorList,
  };

  useEffect(() => {
    let _colorList = [];
    for (let d of category) {
      if (d[0] === "C") {
        _colorList.push("#0487D9");
      } else {
        _colorList.push("#F29F05");
      }
    }

    setColorList(_colorList);

    setSeries([{ data }]);
  }, [data]);

  return (
    series && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Chart options={chartOptions} type="bar" series={series} />
      </div>
    )
  );
};

export default BarChart;
