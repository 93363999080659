import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { headerState, carbonSearchState } from "../../../store/atom";
import { useTranslation } from "react-i18next";
import { Table, Button, message } from "antd";
import data from "../../../data/index.json";
import styles from "@styles/carbon/relationManage.module.css";
import ModifyModal from "./ModifyModal";
import service from "@services/carbontaxService";
import FactorSearchComponent from "../FactorSearchComponent";

const columns1: any = data["carbonTax"]["relationManage"]["columns1"];
const columns2: any = data["carbonTax"]["relationManage"]["columns2"];
const columns3: any = data["carbonTax"]["relationManage"]["columns3"];

const RelationManage = () => {
  const { t } = useTranslation();
  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:carbonEmissions"),
      subTitle: t("main:header:relationManage"),
      path: "/carbonTax/relationManage",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tHeight, setTHeight] = useState<any>(0);
  useEffect(() => {
    const tHeight = (window.innerHeight - 200) / 2;
    setTHeight(tHeight);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isModify, setIsModify] = useState<boolean>(false);
  const [selectData, setSelectData] = useState<any>(null);
  const [emissionList, setEmissionList] = useState<any>(null);
  const [coefficentList, setCoefficentList] = useState<any>(null);
  const [searchObj] = useRecoilState<any>(carbonSearchState);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectData(selectedRows[0]);
    },
  };

  const rowSelection1 = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      let _list = [];
      for (let i of selectedRows) {
        _list.push(i.eqpId);
      }
      setEmissionList(_list);
    },
  };

  const rowSelection2 = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      let _list = [];
      for (let i of selectedRows) {
        _list.push(i.id);
      }
      setCoefficentList(_list);
    },
  };
  const [, setDisable] = useState<boolean>(true);

  const onCreate = () => {
    setIsModify(true);
  };

  const onSave = () => {
    const payload = { type: searchObj.type, emissionList, coefficentList };
    service
      .updateEmissionFactorMapping(payload)
      .then((res: any) => {
        const result = res.status;
        if (result === 200) {
          message.success("배출원/계수 연관 데이터가 변경 되었습니다.");
        } else {
          message.error("배출원/계수 연관 데이터가 변경 되지 않았습니다.");
        }
        setIsModify(false);
      })
      .finally(() => {
        init();
      });
  };

  const handleCancel = () => {
    setIsModify(false);
  };

  const getRelationDetailUpdate = (payload: any) => {
    service
      .updateEmissionFactorMappingDetail(payload)
      .then((res: any) => {
        const result = res.status;
        if (result === 200) {
          message.success("배출원/계수 연관 데이터가 변경 되었습니다.");
        } else {
          message.error("배출원/계수 연관 데이터가 변경 되지 않았습니다.");
        }
        setIsModify(false);
      })
      .finally(() => {
        setIsModify(false);
        init();
      });
  };

  const handleOk = (payload: any) => {
    getRelationDetailUpdate(payload);
  };

  useEffect(() => {
    if (
      emissionList === null ||
      emissionList.length === 0 ||
      coefficentList === null ||
      coefficentList.length === 0
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [emissionList, coefficentList]);

  const init = () => {
    getEmissionFactorMappingByEqp();
    getEmissionFactorMappingByFactor();
    getEmissionFactorMapping();
  };

  const [dataSource1, setDataSource1] = useState<any>();
  const [dataSource2, setDataSource2] = useState<any>();
  const [dataSource3, setDataSource3] = useState<any>();

  // 배출원/계수 연관 조회 - 매핑목록
  const getEmissionFactorMapping = () => {
    service.getEmissionFactorMapping(searchObj).then((res) => {
      const data = res.data.list;
      let _r = [];
      for (let i in data) {
        const _data = { ...data[i], key: Number(i) + 1 };
        _r.push(_data);
      }
      setDataSource3(_r);
    });
  };

  // 배출원 목록
  const getEmissionFactorMappingByEqp = () => {
    service.getEmissionFactorMappingByEqp().then((res) => {
      const data = res.data.list;
      let _r = [];
      for (let i in data) {
        const _data = { ...data[i], key: Number(i) + 1 };
        _r.push(_data);
      }
      setDataSource1(_r);
    });
  };

  // 배출 계수 목록
  const getEmissionFactorMappingByFactor = () => {
    service.getEmissionFactorMappingByFactor(searchObj).then((res) => {
      const data = res.data.list;
      let _r = [];
      for (let i in data) {
        const _data = { ...data[i], key: Number(i) + 1 };
        _r.push(_data);
      }
      setDataSource2(_r);
    });
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.template}>
      <FactorSearchComponent onSearch={init} />
      <div
        style={{
          display: "flex",
          margin: "10px 0px",
        }}
      >
        <div>
          <Button
            type="primary"
              className="searchbtn"
            onClick={onSave}
            disabled={selectData !== null}
          >
            저장
          </Button>
        </div>
      </div>

      <div style={{ flex: 2, display: "flex" }}>
        <div style={{ width: "400px" }}>
          {/* 준비중 */}
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection1,
            }}
            scroll={{ y: tHeight - 200 }}
            dataSource={dataSource1}
            columns={columns1}
            pagination={false}
          />
        </div>
        <div style={{ marginLeft: "20px", flex: 1 }}>
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection2,
            }}
            scroll={{ y: tHeight - 200 }}
            dataSource={dataSource2}
            columns={columns2}
            pagination={false}
          />
        </div>
      </div>

      <div style={{ flex: 3 }}>
        <div className={styles.buttonContainer}>
          <Button
            type="primary"
              className="searchbtn"
            onClick={onCreate}
            disabled={selectData === null}
          >
            수정
          </Button>
        </div>
        <Table
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          scroll={{ y: tHeight - 100 }}
          dataSource={dataSource3}
          columns={columns3}
          pagination={false}
        />
      </div>
      {isModify && (
        <ModifyModal
          visible={isModify}
          handleCancel={handleCancel}
          handleOk={handleOk}
          obj={selectData}
        />
      )}
    </div>
  );
};

export default RelationManage;
