import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import dayjs from "dayjs";
import styles from "@/styles/dataShare/searchComponent.module.css";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  errorSearchState,
  rcpCompanyState,
  rcpSystemState,
  searchState,
  trnCompanyState,
  trnSystemState,
} from "../../store/atom";
import { useTranslation } from "react-i18next";
import dataShareService from "@/services/dataShareService";
import { TbRefresh } from "react-icons/tb";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const today = dayjs(new Date());

const ErrorLogSearchComponent = ({ disabled, onSearch }: any) => {
  const { t } = useTranslation();

  // 날짜 초기 셋팅
  const initDateObj = {
    startDay: dayjs(today).subtract(6, "day").format(dateFormat),
    startTime: today.format("00:00"),
    endDay: dayjs(today).add(1, "day").format(dateFormat),
    endTime: today.format("00:00"),
  };

  // 라디오 버튼 기본값 전체
  const initRadioObj = {
    sCompany: 1,
    sSystem: 1,
    rCompany: 1,
    rSystem: 1,
  };

  // 날짜 변수에 기본값 지정
  const [dateObj, setDateObj] = useState<any>(initDateObj);
  // 라디오 변수에 기본값 지정
  const [radioObj, setRadioObj] = useState<any>(initRadioObj);

  // 전역변수 start
  // trn : 송신
  // rcp : 수신
  const [searchCondition, setSearchCondition] =
    useRecoilState(errorSearchState); // 조회 조건으로 들어가는 변수
  const [trnCompany] = useRecoilState<any>(trnCompanyState); // 송신 기업
  const [trnSystem, setTrnSystem] = useRecoilState<any>(trnSystemState); // 송신 시스템
  const [rcpCompany] = useRecoilState<any>(rcpCompanyState); // 수신 기업
  const [rcpSystem, setRcpSystem] = useRecoilState<any>(rcpSystemState); // 수신 시스템
  // 전역변수 end

  // 송신
  const [trnKey, setTrnKey] = useState(trnCompany[0]?.["label"] || "");
  const [trnSystemKey, setTrnSystemKey] = useState(
    trnSystem[0]?.["label"] || ""
  );

  // 수신
  const [rcpKey, setRcpKey] = useState(rcpCompany[0]?.["label"] || "");
  const [rcpSystemKey, setRcpSystemKey] = useState(
    rcpSystem[0]?.["label"] || ""
  );

  /**
   * @desc 송신 기업 select onChange event
   * @param value
   */
  const handleTrnKeyChange = async (value: any) => {
    setTrnKey(value);

    await dataShareService.getCompanySystemList(value).then((res) => {
      const result = res.data.list;
      let list: any = [];
      for (let r of result) {
        const obj = { value: r.sysId, label: r.sysNm };
        list.push(obj);
      }

      if (list.length === 0) {
        setRadioObj({ ...radioObj, sSystem: 1 });
      }
      setTrnSystem(list);
    });

    setSearchCondition({
      ...searchCondition,
      trnsUserId: value,
      trnsSystemId: trnSystem[0]["value"],
    });

    setTrnSystemKey(trnSystem[0]["label"]);
  };

  /**
   * @desc 수신 기업 select onChange event
   * @param value
   */
  const handleRcpKeyChange = async (value: any) => {
    setRcpKey(value.toString());
    let list: any = [];
    await dataShareService.getCompanySystemList(value).then((res) => {
      const result = res.data.list;

      for (let r of result) {
        const obj = { value: r.sysId, label: r.sysNm };
        list.push(obj);
      }
      if (list.length === 0) {
        setRadioObj({ ...radioObj, rSystem: 1 });
        setRcpSystem([]);
        setRcpSystemKey("");
      } else {
        setRcpSystem(list);
        setRcpSystemKey(list[0]["label"]);
      }
    });

    setSearchCondition({
      ...searchCondition,
      rcptnUserId: value,
      rcptnSystemId: list[0]?.["value"] || "",
    });
  };

  /**
   * @desc 송신 시스템 select onChange event
   * @param value
   */
  const onTrnKeyChange = (value: any) => {
    setTrnSystemKey(value);
    setSearchCondition({
      ...searchCondition,
      trnsSystemId: value,
    });
  };

  /**
   * @desc 수신 시스템 select onChange event
   * @param value
   */
  const onRcpKeyChange = (value: any) => {
    setRcpSystemKey(value);
    setSearchCondition({
      ...searchCondition,
      rcptnSystemId: value,
    });
  };

  /**
   * @desc 검색 버튼 클릭 이벤트
   * 라디오 버튼이 전체로 선택한 항목은 검색 조건을 초기화 한다.
   * 날짜 포맷을 맞춰 준다.
   */
  const handleSearch = () => {
    let searchObj = { ...searchCondition };
    if (radioObj.sCompany === 1) {
      searchObj.trnsUserId = "";
      searchObj.trnsSystemId = "";
    }
    if (radioObj.sSystem === 1) {
      searchObj.trnsSystemId = "";
    }
    if (radioObj.rCompany === 1) {
      searchObj.rcptnUserId = "";
      searchObj.rcptnSystemId = "";
    }
    if (radioObj.rSystem === 1) {
      searchObj.rcptnSystemId = "";
    }

    searchObj.startDateTime =
      dayjs(dateObj.startDay).format("YYYYMMDD") +
      dateObj.startTime.replace(":", "") +
      "00";
    searchObj.endDateTime =
      dayjs(dateObj.endDay).format("YYYYMMDD") +
      dateObj.endTime.replace(":", "") +
      "00";

    setSearchCondition(searchObj);
    onSearch();
  };

  const onInit = () => {
    setDateObj(initDateObj);
    setRadioObj(initRadioObj);
    resetSearchState();
  };

  const onPrevent = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  // 초기 진입시 검색 조건 초기화
  const resetSearchState = useResetRecoilState(searchState);
  useEffect(() => {
    resetSearchState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.searchContainer}>
      <div className={styles.search}>
        <Space size={"middle"} direction={"vertical"}>
          <Row justify="space-between" align="middle">
            <Col>
              <span className={styles.searchText}>
                {t("main:shareData:period")}
              </span>
              <span className={styles.searchDash}></span>
              <RangePicker
                defaultValue={[
                  dayjs(dateObj.startDay, dateFormat),
                  dayjs(dateObj.endDay, dateFormat),
                ]}
                format={"YYYY-MM-DD"}
                disabled={disabled}
                onChange={(date) => {
                  setDateObj((prev: any) => {
                    return {
                      ...prev,
                      startDay: date[0].format(dateFormat),
                      endDay: date[1].format(dateFormat),
                    };
                  });
                  const startDateTime = dayjs(date[0]).format("YYYYMMDDHHmm00");
                  const endDateTime = dayjs(date[1]).format("YYYYMMDDHHmm00");
                  setSearchCondition({
                    ...searchCondition,
                    startDateTime,
                    endDateTime,
                  });
                }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 32]}>
            <Col xs={24} md={12}>
              <span className={styles.searchText}>
                {t("main:shareData:sendingCompany")}
              </span>
              <span className={styles.searchDash}></span>
              <Radio.Group
                className={styles.searchRadio}
                disabled={disabled}
                onChange={(e) => {
                  if (e.target.value === 2) {
                    setSearchCondition({
                      ...searchCondition,
                      trnsUserId: trnCompany[0]["value"],
                    });
                  } else {
                    setSearchCondition({ ...searchCondition, trnsUserId: "" });
                  }
                  setRadioObj({ ...radioObj, sCompany: e.target.value });
                }}
                value={radioObj.sCompany}
              >
                <Radio value={1}>{t("main:shareData:whole")}</Radio>
                <Radio value={2}>
                  <span onClick={onPrevent}>
                    <Select
                      value={trnKey}
                      className={styles.searchSelector}
                      onChange={handleTrnKeyChange}
                      options={trnCompany}
                      disabled={disabled || radioObj.sCompany === 1}
                    />
                  </span>
                </Radio>
              </Radio.Group>
            </Col>
            <Col xs={24} md={12}>
              <span className={styles.searchText}>
                {t("main:shareData:receivingCompany")}
              </span>
              <span className={styles.searchDash}></span>
              <Radio.Group
                className={styles.searchRadio}
                disabled={disabled}
                onChange={(e) => {
                  if (e.target.value === 2) {
                    setSearchCondition({
                      ...searchCondition,
                      rcptnUserId: rcpCompany[0]["value"],
                    });
                    setRcpKey(rcpCompany[0]["value"]);
                  } else {
                    setSearchCondition({ ...searchCondition, rcptnUserId: "" });
                  }
                  setRadioObj({ ...radioObj, rCompany: e.target.value });
                }}
                value={radioObj.rCompany}
              >
                <Radio value={1}>{t("main:shareData:whole")}</Radio>
                <Radio value={2}>
                  <span onClick={onPrevent}>
                    <Select
                      value={rcpKey}
                      className={styles.searchSelector}
                      onChange={handleRcpKeyChange}
                      options={rcpCompany}
                      disabled={disabled || radioObj.rCompany === 1}
                    />
                  </span>
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row gutter={[16, 32]}>
            <Col xs={24} md={12}>
              <span className={styles.searchText}>
                {t("main:shareData:sendingSystem")}
              </span>
              <span className={styles.searchDash}></span>
              <Radio.Group
                className={styles.searchRadio}
                disabled={disabled}
                value={radioObj.sSystem}
                onChange={(e) => {
                  if (e.target.value === 2) {
                    setSearchCondition({
                      ...searchCondition,
                      trnsSystemId: trnSystem[0]["value"],
                    });
                    setTrnSystemKey(trnSystem[0]["value"]);
                  } else {
                    setSearchCondition({
                      ...searchCondition,
                      trnsSystemId: "",
                    });
                  }
                  setRadioObj({ ...radioObj, sSystem: e.target.value });
                }}
              >
                <Radio value={1}>{t("main:shareData:whole")}</Radio>
                <Radio value={2} disabled={radioObj.sCompany === 1}>
                  <span onClick={onPrevent}>
                    <Select
                      className={styles.searchSelector}
                      value={trnSystemKey}
                      onChange={onTrnKeyChange}
                      options={trnSystem}
                      disabled={
                        disabled ||
                        radioObj.sCompany === 1 ||
                        radioObj.sSystem === 1
                      }
                    />
                  </span>
                </Radio>
              </Radio.Group>
            </Col>
            <Col xs={24} md={12}>
              <span className={styles.searchText}>
                {t("main:shareData:receivingSystem")}
              </span>
              <span className={styles.searchDash}></span>
              <Radio.Group
                className={styles.searchRadio}
                disabled={disabled}
                onChange={(e) => {
                  if (e.target.value === 2) {
                    setSearchCondition({
                      ...searchCondition,
                      rcptnSystemId: rcpSystem[0]?.["value"] || "",
                    });
                    setRcpSystemKey(rcpSystem[0]?.["label"] || "");
                  } else {
                    setSearchCondition({
                      ...searchCondition,
                      rcptnSystemId: "",
                    });
                  }
                  setRadioObj({ ...radioObj, rSystem: e.target.value });
                }}
                value={radioObj.rSystem}
              >
                <Radio value={1}>{t("main:shareData:whole")}</Radio>
                <Radio
                  value={2}
                  disabled={radioObj.rCompany === 1 || rcpSystemKey === ""}
                >
                  <span onClick={onPrevent}>
                    <Select
                      className={styles.searchSelector}
                      value={rcpSystemKey}
                      onChange={onRcpKeyChange}
                      options={rcpSystem}
                      disabled={
                        disabled ||
                        radioObj.rCompany === 1 ||
                        radioObj.rSystem === 1
                      }
                    />
                  </span>
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row gutter={[16, 32]}>
            <Col xs={24} md={12}>
              <span className={styles.searchText}>
                {t("main:shareData:datasetName")}
              </span>
              <span className={styles.searchDash}></span>
              <Input
                value={searchCondition.datasetName}
                className={styles.searchInput}
                disabled={disabled}
                onChange={(e) => {
                  setSearchCondition({
                    ...searchCondition,
                    datasetName: e.target.value,
                  });
                }}
              />
            </Col>
            <Col xs={24} md={12}>
              <span className={styles.searchText}>
                {t("main:shareData:dataItemName")}
              </span>
              <span className={styles.searchDash}></span>
              <Input
                value={searchCondition.dataItemName}
                className={styles.searchInput}
                disabled={disabled}
                onChange={(e) => {
                  setSearchCondition({
                    ...searchCondition,
                    dataItemName: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </Space>
      </div>

      <div className={styles.searchExtra}>
        <Button
          type="primary"
          className={styles.searchBtn}
          disabled={disabled}
          onClick={handleSearch}
        >
          {t("main:shareData:search")}
        </Button>
        <Button
          className={styles.initBtn}
          icon={<TbRefresh size={18} />}
          disabled={disabled}
          onClick={onInit}
        >
          {t("main:shareData:initialization")}
        </Button>
      </div>
    </div>
  );
};

export default ErrorLogSearchComponent;
