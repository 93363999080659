import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const LineBasicChart = ({ title, data, width, height, category }: any) => {
  const [series, setSeries] = React.useState<ApexAxisChartSeries>([]);

  const chartOptions: ApexOptions = {
    title: {
      text: title,
      align: "left",
      offsetY: 20,
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "Noto Sans KR",
        color: "#F0F0F0",
      },
    },

    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: "Noto Sans KR",
      },
      x: {
        formatter: function (val) {
          return val + " hour";
        },
      },
      y: {
        // formatter: function (val) {
        //   // return val + "KWh";
        //   return val.toFixed(4);
        // },
      },
      marker: {
        show: true,
      },
    },
    chart: {
      zoom: {
        enabled: false,
      },

      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: category,
      labels: {
        style: {
          fontFamily: "Noto Sans KR",
          colors: "#F0F0F0",
          fontWeight: "bold",
        },
      },
    },
    grid: {
      show: true,
      borderColor: "#90A4AE",
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          return val.toLocaleString();
        },
        style: {
          fontWeight: "bold",
          colors: "#F0F0F0",
          fontSize: "14px",
          fontFamily: "Noto Sans KR",
        },
      },
    },
    legend: {
      offsetY: -10,
      show: true,
      position: "top",
      horizontalAlign: "right",
      fontFamily: "Noto Sans KR",
      fontSize: "16px",
      labels: {
        colors: "#F0F0F0",
      },
      itemMargin: {
        horizontal: 5,
      },
    },
    colors: ["#0487D9", "#FFF466", "#E30039", "#01AB33"],
  };

  useEffect(() => {
    // console.log("@@@@@", data);
    setSeries(data);
  }, [data]);

  return (
    <Chart
      style={
        {
          // background: "#fff",
          // padding: "0px 30px",
          // border: "1px solid #ddd",
          // borderRadius: "10px",
        }
      }
      options={chartOptions}
      type="line"
      series={series}
      width={width}
      height={height}
    />
  );
};

export default LineBasicChart;
