import React, { useEffect, useState } from "react";
import SearchComponent from "../SearchComponent";
import { Table } from "antd";
import type { PaginationProps } from "antd";
import { useRecoilState, useSetRecoilState } from "recoil";
import { headerState, searchState } from "../../../store/atom";
import { useTranslation } from "react-i18next";
import service from "@services/dataShareService";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { useNavigate } from "react-router-dom";
import {ColumnType} from "antd/es/table";
import {ShareHistoryTransData} from "@pages/shareData/history/history";
dayjs.extend(weekday);

const DataCollectionHistory = () => {
  const { t } = useTranslation();

  const setStatus = (data: string) => {
    let result = "";
    switch (data) {
      case "":
        result = "전체";
        break;
      case null:
        result = "-";
        break;
      case "Y":
        result = "완료";
        break;
      case "N":
        result = "수집중";
        break;
      default:
        result = "-";
        break;
    }
    return result;
  };

  const textCompare = (a: any, b: any) => {
    if (a === null && b === null) {
      return 0;
    }
    const result = a.localeCompare(b);
    return result;
  };

  const sendCol: ColumnType<ShareHistoryTransData>[] = [
    { align: "right", width: "40px", title: "No.", dataIndex: "key" },
    {
      align: "left",
      title: t("main:shareData:sendingCompany"),
      dataIndex: "trnsUserNm",
      key: "trnsUserNm",
      sorter: (a: any, b: any) => textCompare(a.trnsUserNm, b.trnsUserNm),
    },
    {
      align: "left",
      title: t("main:shareData:sendingSystem"),
      dataIndex: "trnsSysNm",
      key: "trnsSysNm",
      sorter: (a: any, b: any) => textCompare(a.trnsSysNm, b.trnsSysNm),
    },
    {
      align: "left",
      title: t("main:shareData:receivingCompany"),
      dataIndex: "rcptnUserNm",
      key: "rcptnUserNm",
      sorter: (a: any, b: any) => textCompare(a.rcptnUserNm, b.rcptnUserNm),
    },
    {
      align: "left",
      title: t("main:shareData:receivingSystem"),
      dataIndex: "rcptnSysNm",
      key: "rcptnSysNm",
      sorter: (a: any, b: any) => textCompare(a.rcptnSysNm, b.rcptnSysNm),
    },
    {
      align: "left",
      title: t("main:shareData:datasetName"),
      dataIndex: "datasetName",
      key: "datasetName",
      sorter: (a: any, b: any) => textCompare(a.datasetName, b.datasetName),
    },
    {
      align: "left",
      title: t("main:shareData:datasetUuid"),
      dataIndex: "datasetUuid",
      key: "datasetUuid",
      sorter: (a: any, b: any) => textCompare(a.datasetId, b.datasetId),
    },
    {
      align: "left",
      title: t("main:shareData:sharedDataId"),
      dataIndex: "dataSharId",
      key: "dataSharId",
      sorter: (a: any, b: any) => textCompare(a.dataSharId, b.dataSharId),
    },
    {
      align: "left",
      title: t("main:shareData:sendingType"),
      dataIndex: "trnsMthd",
      key: "trnsMthd",
      sorter: (a: any, b: any) => textCompare(a.trnsMthd, b.trnsMthd),
    },
    {
      align: "left",
      title: t("main:shareData:receivingType"),
      dataIndex: "rcptnMthd",
      key: "rcptnMthd",
      sorter: (a: any, b: any) => textCompare(a.rcptnMthd, b.rcptnMthd),
    },
  ];

  const setHeader = useSetRecoilState(headerState);
  useEffect(() => {
    setHeader({
      title: t("main:header:shareData"),
      subTitle: t("main:header:shareDataHistory"),
      path: "/shareData/history",
    });
  }, []);

  const [searchCondition, setSearchCondition] = useRecoilState(searchState);

  const onSearch = () => {
    getDataStatusList();
  };

  const [dataList, setDataList] = useState<ShareHistoryTransData[]>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  const getDataStatusList = () => {
    const payload = { ...searchCondition, page, size };
    service.getDataHistoryList(payload).then((res) => {
      const data = res.data.list;

      setTotalCount(res.data.totalCount);
      const _size = res.data.pageSize;
      setSize(res.data.pageSize);
      let _r = [];
      for (let i in data) {
        const _data = { ...data[i], key: Number(i) + 1 + (page - 1) * _size };
        _r.push(_data);
      }
      setDataList(_r);
    });
  };

  const navigate = useNavigate();
  const onDetail = (record: ShareHistoryTransData) => {
    navigate("/shareData/history/detail", { state: record });
  };

  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    setPage(pageNumber);
    setSearchCondition({ ...searchCondition, page: pageNumber, size });
  };

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = async (
    current,
    pageSize
  ) => {
    setSize(pageSize);
    setSearchCondition({ ...searchCondition, page: current, size: pageSize });
  };

  useEffect(() => {
    getDataStatusList();
  }, [size, page]);

  return (
    <div>
      <SearchComponent
        period={false}
        onSearch={onSearch}
        param={"shareDataHistory"}
      />
      <Table
        className="dataShare"
        style={{ marginTop: "10px" }}
        dataSource={dataList}
        columns={sendCol}
        scroll={{ x: true }}
        size="small"
        pagination={{
          onShowSizeChange: onShowSizeChange,
          current: page,
          onChange: onChange,
          total: totalCount,
          defaultCurrent: 1,
          pageSize: size,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onDetail(record);
            },
          };
        }}
      />
    </div>
  );
};

export default DataCollectionHistory;
