import React from "react";
import { createBrowserRouter } from "react-router-dom";
import NotFound from "@pages/404";
import DataCollectionStatus from "@pages/shareData/status/DataCollectionStatus";
import DataCollectionHistory from "@pages/shareData/history/DataCollectionHistory";
import DashBoardDefault from "@pages/shareData/dashboard/DashBoardDefault";
import Device from "@pages/analysisData/device/Device";
import Metric from "@pages/analysisData/metric/Metric";
import PoscoDashboard from "@pages/optimumFacility/pDashboard/Dashboard";
import Trend from "@pages/optimumFacility/trend/Trend";
import DataCollectionHistoryDetail from "@pages/shareData/history/DataCollectionHistoryDetail";
import EmissionInfo from "@pages/carbonTax/emissionInfo/EmissionInfo";
import EmissionCoefficent from "@pages/carbonTax/emissionCoefficent/EmissionCoefficent";
import RelationManage from "@pages/carbonTax/relationManage/RelationManage";
import CarbonDashboard from "@pages/carbonTax/dashboard/CarbonDashboard";
import EmissionsCal from "@pages/carbonTax/emissionsCal/EmissionsCal";
import VariationTrend from "@pages/carbonTax/variationTrend/VariationTrend";
import Traceability from "@pages/carbonTax/traceability/Traceability";
import Report from "@pages/carbonTax/report/Report";
import Login from "@pages/login/Login";
import UserInfo from "@pages/shareData/userInfo/UserInfo";
import App from "@/App";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import ErrorLogHistory from "@/pages/errorLog/ErrorLogHistory";

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <DataCollectionStatus />,
      },
      {
        path: "shareData",
        children: [
          {
            path: "dashboard",
            element: <DashBoardDefault />,
          },
          {
            path: "status",
            element: <DataCollectionStatus />, // main page
          },
          {
            path: "errorlog",
            element: <ErrorLogHistory />, // main page
          },
          {
            path: "history",
            element: <DataCollectionHistory />,
          },
          {
            path: "history/detail",
            element: <DataCollectionHistoryDetail />,
          },
          {
            path: "userInfo",
            element: <UserInfo />,
          },
        ],
      },
      {
        path: "analysisData",
        children: [
          {
            index: true,
            element: <Metric />,
          },
          {
            path: "metric",
            element: <Metric />,
          },
          {
            path: "device",
            element: <Device />,
          },
        ],
      },
      {
        path: "optimumFacility",
        children: [
          {
            path: "dashboard",
            element: <PoscoDashboard />,
          },
          {
            path: "trend",
            element: <Trend />,
          },
        ],
      },
      {
        path: "carbonTax",
        children: [
          {
            path: "dashboard",
            element: <CarbonDashboard />,
          },
          {
            path: "emissionInfo",
            element: <EmissionInfo />,
          },
          {
            path: "emissionCoefficent",
            element: <EmissionCoefficent />,
          },
          {
            path: "relationManage",
            element: <RelationManage />,
          },
          {
            path: "emissionsCal",
            element: <EmissionsCal />,
          },
          {
            path: "variationTrend",
            element: <VariationTrend />,
          },
          {
            path: "traceability",
            element: <Traceability />,
          },
          {
            path: "report",
            element: <Report />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: (
      <ProtectedRoute>
        <NotFound />
      </ProtectedRoute>
    ), // 404 페이지도 보호 필요
  },
]);

export default router;
