import React from 'react';
import { LoadingOutlined } from "@ant-design/icons";

const style = {
  display: 'flex',
  justifyContent: 'center',
  margin: '150px'
};

const LoadingSpinner = () => {
  return (
    <div className="spinner" style={style}>
      <LoadingOutlined style={{fontSize: 36, color: '#57BDB2'}} spin/>
    </div>
  );
};

export default LoadingSpinner;